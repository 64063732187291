import React from "react"

const iconGridSizes = ["large", "small"]

export default function IconGrid({ icons, size, separated = false }) {
  if (iconGridSizes.indexOf(size) === -1) {
    size = "small"
  }
  const iconsWithSeparators = []

  if (separated) {
    icons.map((icon, index) => {
      iconsWithSeparators.push(icon)
      if (index !== icons.length - 1) {
        iconsWithSeparators.push(null)
      }
      return null
    })
  }

  const elements = separated ? iconsWithSeparators : icons

  return (
    <div className="icon-grid">
      {elements.map((icon, index) => {
        if (icon) {
          return (
            <div
              key={index}
              className={`icon-grid__icon icon-grid__icon--${size}`}
            >
              <img src={icon.src} alt={icon.label} />
              <h4>{icon.label}</h4>
            </div>
          )
        } else {
          return (
            <div
              key={index}
              className={`icon-grid__separator icon-grid__separator--${size}`}
            ></div>
          )
        }
      })}
    </div>
  )
}
