import React from "react"

export default function ImprintContent() {
  return (
    <>
      <p className="font-weight-bold">MAIKI</p>
      <p>MAIKI a brand of CARDO Holding AG</p>
      <p>Konrad-Zuse-Platz 8</p>
      <p>81829 Munich, Germany</p>
      <p>&nbsp;</p>
      <p>www.maiki.io</p>
      <p>Contact: contact@maiki.io</p>
      <p>&nbsp;</p>
      <p>General Partner: CARDO Holding AG</p>
      <p>Tax-ID CARDO Holding AG: 143/100/23/133</p>
      <p>VAT-ID according &sect; 27a of UStG: : DE311739641</p>
      <p>Registered in Munich: HRB 232590</p>
      <p>&nbsp;</p>
      <p>Responsible for Content (&sect; 10 Abs. 3 MDStV):</p>
      <p>Michael Kochs</p>
      <p>&nbsp;</p>
      <p>Disclaimer and Copyrights:</p>
      <p>&nbsp;</p>
      <p>
        CARDO Holding AG / MAIKI accepts no liability for the content of
        external links. The operators of the linked pages are solely responsible
        for their content.
      </p>
      <p>
        The content, texts, graphics, approaches or product descriptions
        presented on our website unless other stated are the intellectual
        property of CARDO Holding AG. All rights are reserved. Violations will
        be prosecuted.
      </p>
      <p>
        All publications and documents on the website are protected by
        copyright. Any use requires the prior written consent of CARDO Holding
        AG / MAIKI. Violations of copyright will be prosecuted under civil and
        criminal law. This applies in the same way to all copyright infringing
        exploitation actions, e.g. by copying, translation, microfilming and
        storage and processing in electronic systems such as DVD, USB sticks.
        The retrieval of content from this website for internal purposes does
        not grant licenses or other rights for commercial use. It is intended
        exclusively to retrieve the Site Content for internal purposes. Any use
        vis-&agrave;-vis third parties or changes to the site content are not
        permitted. CARDO Holding AG accepts no liability whatsoever for any
        unintended use of the contents.
      </p>
      <p>
        CARDO Holding AG is not liable for objectively unjustified
        misinterpretations of the content of its Internet pages. No assurances,
        solutions, recommendations or warranties of any kind are to be derived
        from the contents. We assume no liability for statements made by third
        parties. The law of the Federal Republic of Germany applies.
      </p>
      <p>&nbsp;</p>
      <p>
        Some images are from the Fotolia/Adobe Stock fund and the license were
        purchased for the use in this website. This concerns the following
        numbers: #100156442, #112468033, #108966109, #49913013, #132309889,
        #131551373, #10298540, AS149345907
      </p>
    </>
  )
}
