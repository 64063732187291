import React from "react"
import { noop } from "../../utils"

export default function Modal({ children, onClick = noop }) {
  return (
    <div className="modal">
      <div className="inner-modal">
        <div className="inner-modal-container">
          <div className="inner-modal-container__header">
            <div className="color--white h3">MAIKI Data Privacy</div>
            <div
              className="inner-modal-container__header__close"
              onClick={onClick}
            >
              <img src="./images/Icon_Close.svg" alt="Close" />
            </div>
          </div>
          <div className="inner-modal-container__body">{children}</div>
        </div>
      </div>
    </div>
  )
}
