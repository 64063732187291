import React from "react"

export default function BackgroundImage({ children, imgUrl, height = "auto" }) {
  const style = {
    background: `url(${imgUrl}) center 0 no-repeat`,
    height: `${height}px`,
  }

  return (
    <div className="background-container container" style={style}>
      {children}
    </div>
  )
}
