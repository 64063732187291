import React, { useState, useEffect, useContext } from "react"
import { GlobalContext } from "../../pages/index"
import { CSSTransition } from "react-transition-group"
import DataPrivacyContent from "../../components/DataPrivacyContent/DataPrivacyContent"
import Modal from "../../components/Modal/Modal"

export default function Form({ action }) {
  const [isPrivacyTermsAccepted, setIsPrivacyTermsAccepted] = useState(false)
  const { isPrivacyTermsOpened, setIsPrivacyTermsOpened } = useContext(
    GlobalContext
  )

  function handleToggleAcceptPrivacyTerms() {
    setIsPrivacyTermsAccepted(prev => !prev)
  }

  function handleTogglePrivacyStatements(e) {
    e.preventDefault()
    setIsPrivacyTermsOpened(prev => !prev)
  }

  useEffect(() => {
    if (isPrivacyTermsOpened) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "unset"
    }
  }, [isPrivacyTermsOpened])

  return (
    <div className="form-container">
      <form action="register.php" method="POST">
        <div className="form-row">
          <div className="form-column">
            <input
              type="text"
              name="firstName"
              required
              placeholder="First Name*"
            />
          </div>
          <div className="form-column">
            <input
              type="text"
              name="lastName"
              required
              placeholder="Last Name*"
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-column">
            <input
              type="text"
              name="company"
              placeholder="Company (optional)"
            />
          </div>
          <div className="form-column">
            <input
              type="text"
              name="industry"
              placeholder="Industry (optional)"
            />
          </div>
        </div>
        <div className="form-row">
          <input
            type="email"
            name="emailAddress"
            id="emailAddress"
            placeholder="Email address"
          />
        </div>
        <div className="form-row">
          <textarea
            name="message"
            id="message"
            cols="30"
            rows="5"
            placeholder="Your message"
          ></textarea>
        </div>
        <div className="form-row">
          <div className="terms-container">
            <div
              className="toggler"
              onClick={handleToggleAcceptPrivacyTerms}
              role="button"
            >
              <div
                className={`toggler__inner ${
                  isPrivacyTermsAccepted ? "toggler__inner--active" : ""
                }`}
              ></div>
            </div>
            <label htmlFor="privacyTerms" className="color--white">
              I accept the{" "}
              <a
                href="#"
                onClick={handleTogglePrivacyStatements}
                className="color--white"
              >
                data privacy
              </a>{" "}
              statements
            </label>
          </div>
          <input
            hidden
            type="checkbox"
            name="privacyTerms"
            id="privacyTerms"
            defaultChecked={isPrivacyTermsAccepted}
          />
        </div>
        <div className="form-row">
          <input
            type="submit"
            value="Send Message"
            disabled={!isPrivacyTermsAccepted}
          />
        </div>
      </form>
      <CSSTransition
        in={isPrivacyTermsOpened}
        timeout={300}
        classNames="modal"
        unmountOnExit
      >
        <Modal onClick={handleTogglePrivacyStatements}>
          <DataPrivacyContent />
        </Modal>
      </CSSTransition>
    </div>
  )
}
