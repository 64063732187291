import React from "react"

export default function StatsGrid({ stats }) {
  return (
    <div className="stats-grid">
      {stats.map(({ value, label }, index) => (
        <div key={index} className="stats-grid__stat text--center">
          <h1 className="color--accent">{value}</h1>
          <h4 className="color--white">{label}</h4>
        </div>
      ))}
    </div>
  )
}
