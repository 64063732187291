import React, { useState, createContext, useEffect } from "react"
import { navigate } from "gatsby"

import withTheme from "../styles/withTheme"
import Hero from "../composites/Hero/Hero"
import Solutions from "../composites/Sections/Solutions/Solutions"
import Capabilities from "../composites/Sections/Capabilities/Capabilities"
import Products from "../composites/Sections/Products/Products"
import BackgroundImage from "../components/BackgroundImage/BackgroundImage"
import bgImage from "../../static/images/Image_2A.png"
import About from "../composites/Sections/About/About"
import JoinNow from "../composites/Sections/JoinNow/JoinNow"
import Register from "../composites/Sections/Register/Register"
import Footer from "../composites/Footer/Footer"
import Separator from "../components/Separator/Separator"

export const GlobalContext = createContext()

export default function Home({ location }) {
  const [isPrivacyTermsOpened, setIsPrivacyTermsOpened] = useState(false)
  const [isImprintOpened, setIsImprintOpened] = useState(false)
  const showConfirmation = location?.hash?.includes("confirm")
  const context = {
    isPrivacyTermsOpened,
    setIsPrivacyTermsOpened,
    isImprintOpened,
    setIsImprintOpened,
  }

  useEffect(() => {
    if (showConfirmation) {
      alert("Your message has been sent, thank you for reaching out.")

      navigate("/")
    }
  }, [showConfirmation])

  return withTheme(
    <GlobalContext.Provider value={context}>
      <Hero />
      <Solutions />
      <Separator />
      <Capabilities />
      <Separator reverse />
      <Products />
      <BackgroundImage imgUrl={bgImage} height="1080" />
      <About />
      <JoinNow />
      <Separator />
      <Register />
      <Footer />
    </GlobalContext.Provider>
  )
}
