import React from "react"
import Spacer from "../../../components/Spacer/Spacer"
import ProgressBar from "../../../components/ProgressBar/ProgressBar"
import StatsGrid from "../../../components/StatsGrid/StatsGrid"

const progressBars = [
  {
    label: "Supplier search",
    value: 90,
    suffix: "faster",
  },
  {
    label: "Quality of matching",
    value: 65,
    suffix: "better",
  },
  {
    label: "Efficiency gain",
    value: 35,
    suffix: "process improvements",
  },
]

const stats = [
  {
    value: "20+",
    label: "Years experience",
  },
  {
    value: ">5000",
    label: "Technologies",
  },
  {
    value: "2+",
    label: "Years Development",
  },
  {
    value: "3",
    label: "AI kernels",
  },
]

export default function Capabilities() {
  return (
    <div className="capabilities-container container">
      <Spacer size="large" />
      <div className="row text--center">
        <h2 className="color--white">Manufacturing Capabilities</h2>
      </div>
      <Spacer size="medium" />
      <div className="row">
        {progressBars.map((bar, index) => (
          <ProgressBar key={index} data={bar} />
        ))}
      </div>
      <Spacer size="medium" />
      <div className="row">
        <StatsGrid stats={stats} />
      </div>
      <Spacer size="large" />
    </div>
  )
}
