import React from "react"

const spacerSizes = ["large", "medium", "small"]

const Spacer = ({ size }) => {
  if (spacerSizes.indexOf(size) === -1) {
    size = "large"
  }

  return (
    <div className="spacer">
      <div className={`spacer--${size}`}></div>
    </div>
  )
}

export default Spacer
