import React from "react"

const logoSizes = ["large", "small"]

const Logo = ({ size }) => {
  if (logoSizes.indexOf(size) === -1) {
    size = "small"
  }

  const classes = `logo logo--${size}`

  return <img className={classes} src="./images/Logo_White.svg" alt="Logo" />
}

export default Logo
