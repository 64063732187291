import React from "react"
import IconGrid from "../../../components/IconGrid/IconGrid"
import Spacer from "../../../components/Spacer/Spacer"

const featuresRow1 = [
  {
    src: "./images/Icon_FullSearchResults.svg",
    label: "Full search results",
  },
  {
    src: "./images/Icon_ConfigurableDashboard.svg",
    label: "Configurable dashboard",
  },
  {
    src: "./images/Icon_PersonalizedAnalysis.svg",
    label: "Personalized analysis",
  },
]

const featuresRow2 = [
  {
    src: "./images/Icon_AboFunction.svg",
    label: "Abo function on new search results",
  },
  {
    src: "./images/Icon_MarketInsights.svg",
    label: "Market insights",
  },
  {
    src: "./images/Icon_MoreFeatures.svg",
    label: "Many more features",
  },
]

export default function JoinNow() {
  return (
    <div className="join-now-container container">
      <div className="row">
        <h1 className="color--primary text--center">Join Now!</h1>
        <p>
          MAIKI went live and we are proud to attract an impressive number of
          buyers and suppliers to take the first steps with us as BETA users.
          With the start of MAIKI we offer a special limited innivation to all
          buyers and suppliers for 6 months. If you are registering your company
          now, you can apply for our BETA phase offer. Instead of using only our
          free entry level, you can apply for the EXPERT-LEVEL (buyers) or
          GOLD-LEVEL (suppliers) for a 6 months free trial period.
        </p>
      </div>
      <Spacer size="small" />
      <div className="row">
        <h4 className="text--center">
          During the BETA phase you will have acccess to:
        </h4>
      </div>
      <Spacer size="large" />
      <div className="row">
        <IconGrid icons={featuresRow1} size="small" />
      </div>
      <Spacer size="large" />
      <div className="row">
        <IconGrid icons={featuresRow2} size="small" />
      </div>
      <Spacer size="large" />
    </div>
  )
}
