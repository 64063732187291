import React from "react"

export default function ProgressBar({ data }) {
  const { label, value, suffix } = data

  return (
    <div className="progress-bar">
      <h4 className="color--white progress-bar__label">{label}</h4>
      <div className="progress-bar__track">
        <div
          className="progress-bar__fill"
          style={{ width: `${value}%` }}
        ></div>
        <span className="progress-bar__status-text color--primary h3">
          {value}% {suffix}
        </span>
      </div>
    </div>
  )
}
