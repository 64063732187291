import React from "react"

import separatorImage from "../../../static/images/Separator.svg"

export default function Separator({ reverse }) {
  return (
    <div
      className={`separator-container container ${
        reverse ? "separator-container--reversed" : ""
      }`}
      style={{
        background: `url(${separatorImage}) center bottom no-repeat`,
      }}
    ></div>
  )
}
