import React from "react"

import "typeface-open-sans"
import "typeface-montserrat"

import "./index.scss"

const withTheme = children => {
  return <>{children}</>
}

export default withTheme
