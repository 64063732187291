import React from "react"

import Spacer from "../../../components/Spacer/Spacer"
import IconGrid from "../../../components/IconGrid/IconGrid"

const maikiAiIcons = [
  {
    src: "./images/Icon_DataAnalysis.svg",
    label: "Data Analysis",
  },
  {
    src: "./images/Icon_Formation.svg",
    label: "Formation",
  },
  {
    src: "./images/Icon_MaikiAI.svg",
    label: "Maiki AI",
  },
  {
    src: "./images/Icon_Suggestion.svg",
    label: "Suggestion",
  },
]

const maikiKB = [
  {
    src: "./images/Icon_Buyer.svg",
    label: "Buyer",
  },
  {
    src: "./images/Icon_Knowledge_Base.svg",
    label: "MAIKI Knowledge Base",
  },
  {
    src: "./images/Icon_Supplier_Capabilities.svg",
    label: "Supplier Capabilities",
  },
]

const Solutions = () => {
  return (
    <div className="solutions-container container" id="solutions">
      <Spacer size="large" />
      <div className="row">
        <div className="w75 w75-xl m-auto text--center">
          <h2 className="color--primary">
            Knowledge-Base driven Matching Platform
          </h2>
          <h4>Find - not search.</h4>
        </div>
      </div>
      <div className="row text-center">
        <p>
          MAIKI is the first knowledge-base matching platform for suppliers and
          buyers focusing on customized parts or build to print parts and
          components. Based on our 3 AI pillars, we provide an easy to use
          approach to improve the process of scouting and engaging suppliers
          based on their technical capabilities.
        </p>
        <p>
          MAIKI saves time and reduces the risk of wrong sourcing decisions and
          subsequent exceeding quality and transportation cost.
        </p>
      </div>
      <Spacer size="large" />
      <div className="row">
        <div className="w75 w75-xl m-auto text--center">
          <h2 className="color--primary">Manufacturing Capabilities</h2>
          <h4>MAIKI AI</h4>
        </div>
        <p>
          The MAIKI AI imakes use of a sophisticated manufacturing and
          production technology expert system to find the best match between the
          buyers’s request and the suppliers’capabilities.
        </p>
      </div>
      <Spacer size="large" />
      <div className="row">
        <IconGrid size="small" icons={maikiAiIcons} separated />
      </div>
      <Spacer size="large" />
      <div className="row">
        <h4 className="text--center">MAIKI Knowledge Base</h4>
        <p>
          The MAIKI AI includes a powerful expert system and knowledge base for
          manufacturing technologies. Buyers can find the right suppliers based
          on the fitting manufacturing processes for the parts and components.
          MAIKI’s conversational AI is guiding the buyer through the process
          that finally leads to a short list of suppliers.
        </p>
      </div>
      <Spacer size="large" />
      <div className="row">
        <IconGrid size="large" icons={maikiKB} separated />
      </div>
      <Spacer size="large" />
    </div>
  )
}

export default Solutions
