import React, { useContext } from "react"
import { CSSTransition } from "react-transition-group"
import Logo from "../../components/Logo/Logo"
import Spacer from "../../components/Spacer/Spacer"
import { GlobalContext } from "../../pages/index"
import Modal from "../../components/Modal/Modal"
import ImprintContent from "../../components/ImprintContent/ImprintContent"

export default function Footer() {
  const {
    setIsPrivacyTermsOpened,
    setIsImprintOpened,
    isImprintOpened,
  } = useContext(GlobalContext)

  function handleOpenPrivacyTerms() {
    setIsPrivacyTermsOpened(true)
  }

  function handleToggleImprint() {
    setIsImprintOpened(previous => !previous)
  }

  return (
    <div className="footer-container container" id="footer">
      <CSSTransition
        in={isImprintOpened}
        timeout={300}
        classNames="modal"
        unmountOnExit
      >
        <Modal onClick={handleToggleImprint}>
          <ImprintContent />
        </Modal>
      </CSSTransition>
      <Spacer size="large" />
      <div className="row">
        <div className="footer-container__columns">
          <div className="footer-container__column">
            <Logo size="small" />
          </div>
          <div className="footer-container__column color--white">
            <p className="font-weight-bold">
              MAIKI a brand of CARDO Holding AG
            </p>
            <p>
              <span className="footer-link-icon">
                <img src="./images/Icon_Pin.svg" alt="Pin" />
              </span>
              Konrad-Zuse-Platz 8, 81820 Munich
            </p>
            <p>
              <span className="footer-link-icon">
                <img src="./images/Icon_Phone.svg" alt="Phone" />
              </span>
              DE +89 1250 102-80
            </p>
            <p>
              <span className="footer-link-icon">
                <img src="./images/Icon_Email.svg" alt="Email" />
              </span>
              register@maiki.io
            </p>
          </div>
          <div className="footer-container__column color--white">
            <p className="font-weight-bold">Other</p>
            <p>
              <a href="https://www.linkedin.com/company/maiki-ag-co-kg/">
                Linkedin
              </a>
            </p>
            <p>
              <a href="#footer" onClick={handleOpenPrivacyTerms}>
                Data Privacy
              </a>
            </p>
            <p>
              <a href="#footer" onClick={handleToggleImprint}>
                Imprint
              </a>
            </p>
          </div>
        </div>
      </div>
      <Spacer size="large" />
    </div>
  )
}
