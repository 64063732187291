import React from "react"

const Nav = () => {
  return (
    <div className="nav-container" id="home">
      <a className="nav-container__link" href="#home">
        Home
      </a>
      <a className="nav-container__link" href="#solutions">
        Solutions
      </a>
      <a className="nav-container__link" href="#products">
        Products
      </a>
      <a className="nav-container__link" href="#about">
        About
      </a>
      <a className="nav-container__link" href="#register">
        Register
      </a>
    </div>
  )
}

export default Nav
