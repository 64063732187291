import React from "react"

import Logo from "../../components/Logo/Logo"
import Button from "../../components/Button/Button"
import Nav from "../Nav/Nav"
import bgImage from "../../../static/images/Image_1A.png"
import BackgroundImage from "../../components/BackgroundImage/BackgroundImage"

const Hero = () => {
  function handleClick() {
    window.location.hash = "#solutions"
  }

  return (
    <BackgroundImage imgUrl={bgImage} height="1080">
      <div className="hero-container hero-container__content row">
        <Nav />
        <div className="hero-container__content__copy">
          <Logo size="large" />
          <p className="hero-container__content__copy__lead">
            MAIKI is the first knowledge-based matching platform for suppliers
            and buyers focusing on customized parts or build to print parts and
            components.
          </p>
          <Button onClick={handleClick}>Explore MAIKI</Button>
        </div>
      </div>
    </BackgroundImage>
  )
}

export default Hero
