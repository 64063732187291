import React from "react"

import Spacer from "../../../components/Spacer/Spacer"

export default function Products() {
  return (
    <div className="products-container container" id="products">
      <Spacer size="large" />
      <div className="row">
        <h1 className="color--primary text--center">Products</h1>
      </div>
      <Spacer size="large" />
      <div className="row products-container__columns">
        <div className="products-container__column">
          <h4 className="text--center">For Buyers:</h4>
          <h3 className="color--primary h1 text--center">Finding Suppliers</h3>
          <p>
            Buyers can subscribe @ MAIKI to find suppliers for build to print
            and individualized products and components. Our MAIKI conversational
            AI leads buyers through the process of finding the right supplier
            based on a manufacturing and production technology knowledge base.
          </p>
          <h4>functionality and module portfolio</h4>
          <ul>
            <li>
              <p>Full search results</p>
            </li>
            <li>
              <p>Configurable dashboard</p>
            </li>
            <li>
              <p>Personalized analysis</p>
            </li>
            <li>
              <p>Abo-function for new search results</p>
            </li>
            <li>
              <p>RFQ module based on blockchain</p>
            </li>
            <li>
              <p>Costing module</p>
            </li>
            <li>
              <p>AI based market insights</p>
            </li>
            <li>
              <p>...and many more features</p>
            </li>
          </ul>
        </div>
        <div className="products-container__column">
          <h4 className="text--center">For suppliers:</h4>
          <h3 className="color--primary h1 text--center">
            Capability Offering
          </h3>
          <p>
            Supplier can subscribe @ MAIKI to present their company and
            technical capabilities on the matching marketplace. Our
            knowledge-based approach guides the supplier to provide the right
            information to be found by buyers. This information basically
            represents the supplier’s manufacturing footprint. MAIKI is offering
            self-assessments and additional levels of auditor assessment to
            prove the manufacturing footprint. functionality and module
            portfolio:
          </p>
          <h4>functionality and module portfolio</h4>
          <ul>
            <li>
              <p>Configurable dashboard</p>
            </li>
            <li>
              <p>Rating of capabilities</p>
            </li>
            <li>
              <p>Capacity marketplace module</p>
            </li>
            <li>
              <p>Tender access module</p>
            </li>
            <li>
              <p>Market insights</p>
            </li>
            <li>
              <p>...and many more features</p>
            </li>
          </ul>
        </div>
      </div>
      <Spacer size="large" />
    </div>
  )
}
