import React from "react"
import Spacer from "../../../components/Spacer/Spacer"

export default function About() {
  return (
    <div className="about-container container" id="about">
      <Spacer size="small" />
      <div className="row">
        <h1 className="color--primary text--center">The story behind MAIKI</h1>
        <h4 className="text--center">MAIKI Knowledge Base</h4>
        <p>
          With more than 20 years of experience in global procurement we can say
          that the BIGGEST CHALLENGE in GLOBAL PROCUREMENT, is to find QUALIFIED
          SUPPLIERS that match our high requirements. The rapid development of
          the internet in the last 10 years made us and many other professional
          buyers & engineers hope that our task would become easier and more
          effective, unfortunately the disillusionment followed soon.
        </p>
        <p>
          SUPPLIERS are still spending time, resources and billions of Dollars
          to attract the RIGHT CLIENTS and to BE FOUND.BUYERS are still spending
          time, resources and billions of Dollars to find the right SUPPLIERS
          with a HIGH QUALITY MATCHING.
        </p>
        <p>
          This motivated us to develop a ground-breaking new approach that
          improves the MATCHING between buyers’ search and SUPPLIER’S
          CAPABILITIES. During 2 years of preparation we implemented more than
          20 years of experience into an new solution: MAIKI.
        </p>
        <p>
          Based on new algorithms and supported by the MAIKI AI we are making
          your work SIMPLY SIMPLER.
        </p>
        <p>
          Our vision is to provide a platform which is different to the existing
          approaches. Simplicity, smart functions and the intelligent behavior
          of MAIKI, opens the door to transformation to digital procurement. Our
          differentiator is our AI KERNEL, and TECHNICAL DRIVEN ALGORITHMS
          covering manufacturing and production technologies for all common
          products in major industries. JOIN NOW!
        </p>
      </div>
      <Spacer size="large" />
    </div>
  )
}
