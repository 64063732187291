import React from "react"
import Spacer from "../../../components/Spacer/Spacer"
import Form from "../../Form/Form"

export default function Register() {
  return (
    <div className="register-container container" id="register">
      <Spacer size="large" />
      <div className="row color--white text--center">
        <h1>Register</h1>
        <h4>Get the acces to our beta phase offer now!</h4>
        <p>
          6 Month free Expert-Level subscription for buyers or Gold-Level
          subscription for suppliers
        </p>
      </div>
      <Spacer size="small" />
      <div className="row">
        <Form />
      </div>
      <Spacer size="large" />
    </div>
  )
}
