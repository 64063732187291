import React from "react"

export default function DataPrivacyContent() {
  return (
    <>
      <p className="font-weight-bold">
        <strong>PRIVACY POLICY</strong>
      </p>
      <p>
        <strong>
          Information about the person responsible and the data protection
          officer
        </strong>
      </p>
      <p>
        <strong>
          <br />{" "}
        </strong>
        Responsible for the MAIKI&reg; brand website (www.maiki.io) (this
        "Website")
      </p>
      <p>
        CARDO Holding AG with its registered office at Konrad-Zuse-Platz 8,
        81829 Munich, Germany, registered in Munich. Germany under the
        registration number HRA 109383.
        <span class="Apple-converted-space">&nbsp;</span>
      </p>
      <p>Phone: +49 (0) 89 125010279</p>
      <p>
        E-Mail:<span class="Apple-converted-space">&nbsp; </span>
        datenschutz@maiki.io
      </p>
      <p>
        Internet: <a href="http://www.maiki.io">www.maiki.io</a>
      </p>
      <p>(hereinafter "MAIKI" or "we")</p>
      <p>
        All interested parties and visitors to our website can reach our data
        protection officer at
      </p>
      <p>
        Ralf Reuspch
        <br /> MAIKI
        <br /> Konrad Zuse Place
        <br /> <span class="Apple-converted-space">&nbsp;</span>881829 Munich
        <br /> E-mail:{" "}
        <a href="http://www.maiki.io">
          datenschutz@maiki.ioInternet: www.maiki.io{" "}
          <span class="Apple-converted-space">&nbsp;</span>
        </a>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>Privacy</strong>
      </p>
      <p>
        We have drawn up this data protection declaration (version
        11.08.2020-311202202) to explain to you, in accordance with the
        provisions of the{" "}
        <a href="https://eur-lex.europa.eu/legal-content/DE/ALL/?uri=celex%253A32016R0679&amp;tid=311202202">
          basic data protection regulation (EU) 2016/679,
        </a>{" "}
        what information we collect, how we use data and what decision-making
        options you have as a visitor to this website.
      </p>
      <p>
        Unfortunately it is in the nature of things that these explanations
        sound very technical, but we have tried to describe the most important
        things as simple and clear as possible.
      </p>
      <p>
        <strong>Automatic data storage</strong>
      </p>
      <p>
        When you visit websites today, certain information is automatically
        created and stored, including on this website.
      </p>
      <p>
        When you visit our website as you are doing right now, our web server
        (computer on which this website is stored) automatically saves data such
        as
      </p>
      <ul>
        <li>the address (URL) of the accessed web page</li>
        <li>Browser and browser version</li>
        <li>the operating system used</li>
        <li>the address (URL) of the previously visited page (referrer URL)</li>
        <li>the host name and IP address of the device being accessed</li>
        <li>Date and time</li>
      </ul>
      <p>in files (web server log files).</p>
      <p>
        Usually web server log files are stored for two weeks and then
        automatically deleted. We do not pass on this data, but cannot exclude
        the possibility that this data may be viewed in the event of illegal
        behavior.
      </p>
      <p>
        <strong>Cookies</strong>
      </p>
      <p>
        Our website uses HTTP cookies to store user-specific data. <br /> In the
        following we explain what cookies are and why they are used so that you
        can better understand the following privacy policy.
      </p>
      <p>
        <strong>What exactly are cookies?</strong>
      </p>
      <p>
        Whenever you surf the Internet, you use a browser. Well-known browsers
        include Chrome, Safari, Firefox, Internet Explorer and Microsoft Edge.
        Most websites store small text files in your browser. These files are
        called cookies.
      </p>
      <p>
        One thing cannot be dismissed: Cookies are really useful little helpers.
        Almost all websites use cookies. More precisely, they are HTTP cookies,
        since there are other cookies for other applications. HTTP cookies are
        small files that are stored on your computer by our website. These
        cookie files are automatically stored in the cookie folder, quasi the
        "brain" of your browser. A cookie consists of a name and a value. When
        defining a cookie, one or more attributes must also be specified.
      </p>
      <p>
        Cookies store certain user data about you, such as language or personal
        page settings. When you return to our site, your browser transmits the
        "user-related" information back to our site. Thanks to the cookies, our
        website knows who you are and offers you your usual default settings. In
        some browsers, each cookie has its own file, in others, such as Firefox,
        all cookies are stored in a single file.
      </p>
      <p>
        There are both first-party cookies and third-party cookies. First-party
        cookies are created directly by our site, third-party cookies are
        created by partner sites (e.g. Google Analytics). Each cookie is unique
        because each cookie stores different data. The expiration time of a
        cookie also varies from a few minutes to a few years. Cookies are not
        software programs and do not contain viruses, trojans or other
        "malware". Cookies also cannot access information on your PC.
      </p>
      <p>For example, cookie data can look like this:</p>
      <ul>
        <li>Name: _ga</li>
        <li>Expiration period: 2 years</li>
        <li>Usage: differentiation of website visitors</li>
        <li>Example value: GA1.2.1326744211.152311202202</li>
      </ul>
      <p>A browser should support the following minimum sizes:</p>
      <ul>
        <li>A cookie should contain at least 4096 bytes</li>
        <li>At least 50 cookies should be able to be stored per domain</li>
        <li>A total of at least 3000 cookies should be able to be stored</li>
      </ul>
      <p>
        <strong>What types of cookies are there?</strong>
      </p>
      <p>
        The question of which cookies we use in particular depends on the
        services used and is clarified in the following sections of the privacy
        policy. At this point we would like to briefly discuss the different
        types of HTTP cookies.
      </p>
      <p>You can distinguish 4 types of cookies:</p>
      <p>
        <strong>
          Absolutely necessary Cookies
          <br />{" "}
        </strong>
        These cookies are necessary to ensure basic functions of the website.
        For example, these cookies are needed when a user adds a product to the
        shopping cart, then continues surfing on other pages and only goes to
        checkout later. These cookies do not delete the shopping cart, even if
        the user closes his browser window.
      </p>
      <p>
        <strong>
          Functional Cookies
          <br />{" "}
        </strong>
        These cookies collect information about user behavior and whether the
        user receives any error messages. These cookies are also used to measure
        the loading time and the behavior of the website in different browsers.
      </p>
      <p>
        <strong>
          Target-oriented cookies
          <br />{" "}
        </strong>
        These cookies ensure a better user experience. For example, entered
        locations, font sizes or form data are stored.
      </p>
      <p>
        <strong>
          Advertising cookies
          <br />{" "}
        </strong>
        These cookies are also called targeting cookies. They are used to
        deliver customized advertising to the user. This can be very practical,
        but also very annoying.
      </p>
      <p>
        Usually, the first time you visit a website, you will be asked which of
        these types of cookies you wish to accept. And of course this decision
        is also stored in a cookie.
      </p>
      <p>
        <strong>How can I delete cookies?</strong>
      </p>
      <p>
        How and whether you want to use cookies is up to you. Regardless of the
        service or website from which the cookies originate, you always have the
        option of deleting cookies, allowing them only partially or deactivating
        them. For example, you can block third-party cookies, but allow all
        other cookies.
      </p>
      <p>
        If you want to find out which cookies are stored in your browser when
        you change or delete cookie settings, you can find this in your browser
        settings:
      </p>
      <p>
        <a href="https://support.google.com/chrome/answer/95647?tid=311202202">
          Chrome: Delete, activate and manage cookies in Chrome
          <span class="Apple-converted-space">&nbsp;</span>
        </a>
      </p>
      <p>
        <a href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=311202202">
          Safari: Manage cookies and website data with Safari
          <span class="Apple-converted-space">&nbsp;</span>
        </a>
      </p>
      <p>
        <a href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=311202202">
          Firefox: delete cookies to remove data that websites have placed on
          your computer<span class="Apple-converted-space">&nbsp;</span>
        </a>
      </p>
      <p>
        <a href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=311202202">
          Internet Explorer: Delete and manage cookies
          <span class="Apple-converted-space">&nbsp;</span>
        </a>
      </p>
      <p>
        <a href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=311202202">
          Microsoft Edge: Delete and manage cookies
          <span class="Apple-converted-space">&nbsp;</span>
        </a>
      </p>
      <p>
        If you do not wish to receive cookies, you can set your browser to
        notify you whenever a cookie is set. In this way, you can decide for
        each individual cookie whether or not you wish to accept it. The
        procedure varies from browser to browser. The best thing to do is to
        look for the instructions in Google with the search term "Delete cookies
        Chrome" or "Deactivate cookies Chrome" in the case of a Chrome browser
        or replace the word "Chrome" with the name of your browser, e.g. Edge,
        Firefox, Safari.
      </p>
      <p>
        <strong>What about my privacy?</strong>
      </p>
      <p>
        The so-called "cookie guidelines" have been in place since 2009. These
        state that the storage of cookies requires your consent. Within the EU
        countries, however, there are still very different reactions to these
        guidelines. In Germany, the cookie guidelines have not been implemented
        as national law. Instead, the implementation of this guideline was
        largely carried out in &sect; 15 para.3 of the Telemediengesetz (TMG).
      </p>
      <p>
        If you want to know more about cookies and are not afraid of technical
        documentation, we recommend https://tools.ietf.org/html/rfc6265, the
        Internet Engineering Task Force (IETF) Request for Comments called "HTTP
        State Management Mechanism".
      </p>
      <p>
        <strong>Storage of personal data</strong>
      </p>
      <p>
        Personal information that you submit to us electronically on this
        website, such as your name, e-mail address, postal address or other
        personal information when submitting a form or comments on the blog,
        together with the time and IP address, will only be used by us for the
        purpose stated, kept secure and not disclosed to third parties.
      </p>
      <p>
        We use your personal data only for communication with those visitors who
        expressly wish to contact us and for the processing of the services and
        products offered on this website. We will not pass on your personal data
        without your consent, but we cannot exclude the possibility that this
        data may be viewed in the event of unlawful behaviour.
      </p>
      <p>
        If you send us personal data by e-mail - thus off this website - we
        cannot guarantee secure transmission and protection of your data. We
        recommend that you never send confidential data by e-mail without
        encryption.
      </p>
      <p>
        According to{" "}
        <a href="https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679&amp;from=DE&amp;tid=311202202">
          article 6 paragraph 1 a DSGVO
        </a>{" "}
        (lawfulness of processing), the legal basis is that you give us your
        consent to process the data you have entered. You can revoke this
        consent at any time - an informal e-mail is sufficient, you will find
        our contact details in the imprint.
      </p>
      <p>
        <strong>
          Rights according to the basic data protection regulation
        </strong>
      </p>
      <p>
        In accordance with the provisions of the DSGVO, you are basically
        entitled to the following rights:
      </p>
      <ul>
        <li>Right of rectification (Article 16 DSGVO)</li>
        <li>Right of deletion ("right to be forgotten") (Article 17 DSGVO)</li>
        <li>Right to restrict processing (Article 18 DSGVO)</li>
        <li>
          Right of notification - Obligation to notify in connection with the
          correction or deletion of personal data or the restriction of
          processing (Article 19 DPA)
        </li>
        <li>Right to data transferability (Article 20 DSGVO)</li>
        <li>Right of objection (Article 21 DSGVO)</li>
        <li>
          Right not to be subject to a decision based solely on automated
          processing, including profiling (Article 22 DPA)
        </li>
      </ul>
      <p>
        If you believe that the processing of your data violates data protection
        law or your data protection rights have otherwise been violated in any
        way, you can contact the{" "}
        <a href="https://www.bfdi.bund.de">
          Federal Commissioner for Data Protection and Freedom of Information
          (BfDI)
        </a>
      </p>
      <p>
        <strong>Evaluation of visitor behavior</strong>
      </p>
      <p>
        In the following data protection declaration, we inform you whether and
        how we evaluate data from your visit to this website. The evaluation of
        the collected data is usually anonymous and we cannot draw any
        conclusions about your personal behavior on this website.
      </p>
      <p>
        You can find out more about how to object to this evaluation of visit
        data in the following data protection declaration.
      </p>
      <p>
        <strong>TLS encryption with https</strong>
      </p>
      <p>
        We use https to transmit data tap-proof on the Internet (data protection
        through technology design{" "}
        <a href="https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679&amp;from=DE&amp;tid=311202202">
          article 25 paragraph 1 DSGVO
        </a>
        ). By using TLS (Transport Layer Security), an encryption protocol for
        secure data transmission on the Internet, we can ensure the protection
        of confidential data. You can recognize the use of this data
        transmission security by the small lock symbol in the upper left corner
        of the browser and the use of the scheme https (instead of http) as part
        of our internet address.
      </p>
      <p>
        <strong>Google Maps Privacy Policy</strong>
      </p>
      <p>
        On our website we use Google Maps from Google Inc. For the European
        area, the company Google Ireland Limited (Gordon House, Barrow Street
        Dublin 4, Ireland) is responsible for all Google services. With Google
        Maps we can show you locations better and thus adapt our service to your
        needs. By using Google Maps, data is transferred to Google and stored on
        the Google servers. Here we would like to go into more detail about what
        Google Maps is, why we use this Google service, what data is stored and
        how you can prevent this.
      </p>
      <p>
        <strong>What is Google Maps?</strong>
      </p>
      <p>
        Google Maps is an Internet map service of the company Google. With
        Google Maps you can search online for exact locations of cities, sights,
        accommodations or companies using a PC, tablet or app. If companies are
        represented on Google My Business, additional information about the
        company is displayed next to the location. In order to display the
        directions, map sections of a location can be integrated into a website
        using HTML code. Google Maps shows the surface of the earth as a road
        map or as an aerial or satellite image. Thanks to the Street View images
        and the high-quality satellite images, very accurate representations are
        possible.
      </p>
      <p>
        <strong>Why do we use Google Maps on our website?</strong>
      </p>
      <p>
        All our efforts on this site are aimed at providing you with a useful
        and meaningful time on our website. Through the integration of Google
        Maps we can provide you with the most important information about
        various locations. You can see at a glance where we have our
        headquarters. The route description always shows you the best or fastest
        way to reach us. You can call up the directions for routes by car,
        public transport, on foot or by bicycle. For us the provision of Google
        Maps is part of our customer service.
      </p>
      <p>
        <strong>Which data is stored by Google Maps?</strong>
      </p>
      <p>
        In order for Google Maps to offer its service fully, the company must
        collect and store data about you. This includes the search terms
        entered, your IP address and also the latitude and longitude
        coordinates. If you use the route planner function, the entered start
        address is also saved. However, this data storage happens on the
        websites of Google Maps. We can only inform you about it, but we cannot
        influence it. Since we have integrated Google Maps into our website,
        Google sets at least one cookie (name: NID) in your browser. This cookie
        stores data about your user behavior. Google uses this data primarily to
        optimize its own services and to provide individual, personalized
        advertising for you.
      </p>
      <p>
        The following cookie is set in your browser due to the integration of
        Google Maps:
      </p>
      <p>
        <strong>Name:</strong> NID
        <br /> <strong>Wert:</strong> 188=h26c1Ktha7fCQTx8rXgLyATyITJ311202202-5
        <br /> <strong>Purpose:</strong> NID is used by Google to adapt
        advertisements to your Google search. Google uses the cookie to
        "remember" your most frequently entered search queries or your previous
        interaction with ads. So you always get customized ads. The cookie
        contains a unique ID that Google uses to collect your personal
        preferences for advertising purposes.
        <br /> <strong>Expiration date:</strong> after 6 months
      </p>
      <p>
        <strong>Note:</strong> We cannot guarantee the completeness of the
        stored data. Especially when using cookies, changes can never be ruled
        out. In order to identify the cookie NID, a separate test page was
        created where only Google Maps was integrated.
      </p>
      <p>
        <strong>How long and where is the data stored?</strong>
      </p>
      <p>
        The Google servers are located in data centers around the world.
        However, most servers are located in America. For this reason, your data
        is also increasingly stored in the USA. Here you can find out exactly
        where the Google data centers are located:{" "}
        <a href="https://www.google.com/about/datacenters/inside/locations/?hl=de">
          https://www.google.com/about/datacenters/inside/locations/?hl=de
          <span class="Apple-converted-space">&nbsp;</span>
        </a>
      </p>
      <p>
        Google distributes the data on different data carriers. This way the
        data can be accessed more quickly and is better protected against
        possible manipulation attempts. Each data center also has special
        emergency programs. If, for example, there are problems with the Google
        hardware or a natural disaster paralyses the servers, the data is still
        protected.
      </p>
      <p>
        Google stores some data for a specified period of time. For other data,
        Google only offers the option to delete them manually. The company also
        anonymizes information (such as advertising data) in server logs by
        deleting part of the IP address and cookie information after 9 and 18
        months respectively.
      </p>
      <p>
        <strong>How can I delete my data or prevent data storage?</strong>
      </p>
      <p>
        With the automatic deletion of location and activity data introduced in
        2019, location and web/app activity information is stored for either 3
        or 18 months, depending on your decision, and then deleted. You can also
        manually delete this data from your history at any time via your Google
        Account. If you want to completely prevent your location data from being
        recorded, you must pause the "Web and App activity" section in your
        Google Account. Click "Data and personalization" and then click the
        "Activity setting" option. Here you can turn the activities on or off.
      </p>
      <p>
        You can also deactivate, delete or manage individual cookies in your
        browser. Depending on which browser you use, this always works slightly
        differently. The following instructions show you how to manage cookies
        in your browser:
      </p>
      <p>
        <a href="https://support.google.com/chrome/answer/95647?tid=311202202">
          Chrome: Delete, activate and manage cookies in Chrome
          <span class="Apple-converted-space">&nbsp;</span>
        </a>
      </p>
      <p>
        <a href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=311202202">
          Safari: Manage cookies and website data with Safari
          <span class="Apple-converted-space">&nbsp;</span>
        </a>
      </p>
      <p>
        <a href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=311202202">
          Firefox: delete cookies to remove data that websites have placed on
          your computer<span class="Apple-converted-space">&nbsp;</span>
        </a>
      </p>
      <p>
        <a href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=311202202">
          Internet Explorer: Delete and manage cookies
          <span class="Apple-converted-space">&nbsp;</span>
        </a>
      </p>
      <p>
        <a href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=311202202">
          Microsoft Edge: Delete and manage cookies
          <span class="Apple-converted-space">&nbsp;</span>
        </a>
      </p>
      <p>
        If you do not wish to receive cookies, you can set your browser to
        notify you whenever a cookie is set. In this way, you can decide for
        each individual cookie whether you want to allow it or not.
      </p>
      <p>
        Google is an active participant in the EU-U.S. Privacy Shield Framework,
        which regulates the correct and secure transfer of personal data. You
        can find more information on this at
        https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI. If you
        want to learn more about Google's data processing, we recommend that you
        read the company's own privacy policy at{" "}
        <a href="https://policies.google.com/privacy?hl=de">
          https://policies.google.com/privacy?hl=de.
        </a>
      </p>
      <p>
        <strong>Google Fonts Privacy Policy</strong>
      </p>
      <p>
        On our website we use Google Fonts. These are the "Google fonts" of the
        company Google Inc. For the European area the company Google Ireland
        Limited (Gordon House, Barrow Street Dublin 4, Ireland) is responsible
        for all Google services.
      </p>
      <p>
        To use Google fonts, you do not need to log in or set a password.
        Furthermore, no cookies are stored in your browser. The files (CSS,
        fonts) are requested via the Google domains fonts.googleapis.com and
        fonts.gstatic.com. According to Google, the requests for CSS and fonts
        are completely separate from all other Google services. If you have a
        Google Account, you do not need to worry about your Google Account
        information being submitted to Google while using Google Fonts. Google
        records the use of CSS (Cascading Style Sheets) and the fonts used and
        stores this information securely. We will see in detail how the data
        storage looks exactly.
      </p>
      <p>
        <strong>What are Google Fonts?</strong>
      </p>
      <p>
        Google Fonts (formerly Google Web Fonts) is a directory of over 800
        fonts that{" "}
        <a href="https://de.wikipedia.org/wiki/Google_LLC?tid=311202202">
          Google
        </a>{" "}
        makes available to its users free of charge.
      </p>
      <p>
        Many of these fonts are published under the SIL Open Font License, while
        others are published under the Apache license. Both are free software
        licenses.
      </p>
      <p>
        <strong>Why do we use Google Fonts on our website?</strong>
      </p>
      <p>
        With Google Fonts we can use fonts on our own website, but we don't have
        to upload them to our own server. Google Fonts is an important component
        to keep the quality of our website high. All Google fonts are
        automatically optimized for the web and this saves data volume and is a
        great advantage especially for use with mobile devices. If you visit our
        site, the low file size ensures a fast loading time. Furthermore, Google
        Fonts are secure web fonts. Different image synthesis systems
        (rendering) in different browsers, operating systems and mobile devices
        can lead to errors. Such errors can visually distort some texts or
        entire web pages. Thanks to the fast Content Delivery Network (CDN),
        there are no cross-platform problems with Google Fonts. Google Fonts
        supports all major browsers (Google Chrome, Mozilla Firefox, Apple
        Safari, Opera) and works reliably on most modern mobile operating
        systems, including Android 2.2+ and iOS 4.2+ (iPhone, iPad, iPod). So we
        use Google Fonts to make our entire online service as beautiful and
        consistent as possible.
      </p>
      <p>
        <strong>Which data is stored by Google?</strong>
      </p>
      <p>
        When you visit our website, the fonts are reloaded via a Google server.
        This external call transfers data to the Google servers. In this way
        Google also recognizes that you or your IP address are visiting our
        website. The Google Fonts API was developed to reduce the use, storage
        and collection of end user data to what is necessary for a proper
        provision of fonts. By the way, API stands for "Application Programming
        Interface" and serves, among other things, as a data transmitter in the
        software sector.
      </p>
      <p>
        Google Fonts securely stores CSS and font requests at Google and is
        therefore protected. Through the collected usage figures, Google can
        determine how well each font is received. Google publishes the results
        on internal analysis pages, such as Google Analytics. Google also uses
        data from its own web crawler to determine which websites use Google
        fonts. This data is published in the BigQuery database of Google Fonts.
        Entrepreneurs and developers use Google's BigQuery web service to
        examine and move large amounts of data.
      </p>
      <p>
        However, it should also be noted that each Google Font request
        automatically sends information such as language settings, IP address,
        browser version, browser screen resolution and browser name to the
        Google servers. Whether this data is also stored cannot be clearly
        determined or is not clearly communicated by Google.
      </p>
      <p>
        <strong>How long and where is the data stored?</strong>
      </p>
      <p>
        Google stores requests for CSS assets for one day on its servers, which
        are mainly located outside the EU. This allows us to use the fonts with
        the help of a Google style sheet. A stylesheet is a style template that
        allows you to easily and quickly change, for example, the design or font
        of a web page.
      </p>
      <p>
        The font files are stored at Google for one year. Google's goal is to
        improve the loading time of websites. When millions of web pages link to
        the same fonts, they are cached after the first visit and reappear
        immediately on all other web pages visited later. Sometimes Google
        updates font files to reduce file size, increase language coverage and
        improve design.
      </p>
      <p>
        <strong>How can I delete my data or prevent data storage?</strong>
      </p>
      <p>
        The data that Google stores for a day or a year cannot be simply
        deleted. The data is automatically transmitted to Google when the page
        is called up. To delete this data prematurely, you must{" "}
        <a href="https://support.google.com/?hl=de&amp;tid=311202202">
          contact
        </a>{" "}
        Google support at{" "}
        <a href="https://support.google.com/?hl=de&amp;tid=311202202">
          https://support.google.com/?hl=de&amp;tid=311202202.
        </a>{" "}
        In this case you only prevent data storage if you do not visit our site.
      </p>
      <p>
        Unlike other web fonts, Google allows us unlimited access to all fonts.
        So we have unlimited access to a sea of fonts and can thus get the most
        out of our website. More about Google Fonts and other questions can be
        found at https://developers.google.com/fonts/faq?tid=311202202. There
        Google deals with privacy issues, but really detailed information about
        data storage is not included. It is relatively difficult to get really
        detailed information about stored data from Google.
      </p>
      <p>
        You can also read at{" "}
        <a href="https://policies.google.com/privacy?hl=de&amp;tid=311202202">
          https://www.google.com/intl/de/policies/privacy/
        </a>{" "}
        which data are basically collected by Google and for what purpose these
        data are used.
      </p>
      <p>
        <strong>Google Fonts Local Privacy Policy</strong>
      </p>
      <p>
        On our website we use Google Fonts from Google Inc. The company Google
        Ireland Limited (Gordon House, Barrow Street Dublin 4, Ireland) is
        responsible for the European area. We have integrated the Google fonts
        locally, i.e. on our web server - not on the servers of Google. This
        means that there is no connection to Google servers and therefore no
        data transfer or storage.
      </p>
      <p>
        <strong>What are Google Fonts?</strong>
      </p>
      <p>
        In the past, Google Fonts was also called Google Web Fonts. This is an
        interactive directory with over 800 fonts, which{" "}
        <a href="https://de.wikipedia.org/wiki/Google_LLC?tid=311202202">
          Google
        </a>{" "}
        provides free of charge. With Google Fonts you could use fonts without
        uploading them to your own server. But to prevent any information
        transfer to Google servers, we downloaded the fonts to our server. In
        this way, we act in accordance with data protection laws and do not send
        any data to Google Fonts.
      </p>
      <p>
        Unlike other web fonts, Google allows us unlimited access to all fonts.
        So we have unlimited access to a sea of fonts and can thus get the most
        out of our website. More about Google Fonts and other questions can be
        found at{" "}
        <a href="https://developers.google.com/fonts/faq?tid=311202202">
          https://developers.google.com/fonts/faq?tid=311202202.
        </a>
      </p>
      <p>
        <strong>Google Analytics Privacy Policy</strong>
      </p>
      <p>
        On our website we use the analysis tracking tool Google Analytics (GA)
        of the American company Google Inc. For the European area the company
        Google Ireland Limited (Gordon House, Barrow Street Dublin 4, Ireland)
        is responsible for all Google services. Google Analytics collects data
        about your actions on our website. For example, when you click on a
        link, this action is stored in a cookie and sent to Google Analytics.
        With the help of the reports we receive from Google Analytics, we can
        better tailor our website and services to your needs. In the following
        we will go into more detail about the tracking tool and inform you in
        particular about what data is stored and how you can prevent this.
      </p>
      <p>
        <strong>What is Google Analytics?</strong>
      </p>
      <p>
        Google Analytics is a tracking tool that is used for traffic analysis of
        our website. To make Google Analytics work, a tracking code is built
        into the code of our website. When you visit our website, this code
        records various actions that you perform on our website. As soon as you
        leave our website, this data is sent to the Google Analytics servers and
        stored there.
      </p>
      <p>
        Google processes the data and we get reports about your user behavior.
        These reports can include the following:
      </p>
      <ul>
        <li>
          Target group reports: Through target group reports we get to know our
          users better and know more precisely who is interested in our service.
        </li>
        <li>
          Advertisement Reports: Advertisement reports help us to analyze and
          improve our online advertising.
        </li>
        <li>
          Acquisition reports: Acquisition reports provide us with helpful
          information on how we can get more people interested in our service.
        </li>
        <li>
          Behavioral Reports: Here we learn how you interact with our website.
          We can track which path you take on our site and which links you click
          on.
        </li>
        <li>
          Conversion reports: Conversion is the name given to a process in which
          you perform a desired action based on a marketing message. For
          example, when you go from a mere website visitor to a buyer or
          newsletter subscriber. We use these reports to learn more about how
          our marketing activities are received by you. This is how we want to
          increase our conversion rate.
        </li>
        <li>
          Real-time reports: Here we always know immediately what is happening
          on our website. For example, we can see how many users are reading
          this text.
        </li>
      </ul>
      <p>
        <strong>Why do we use Google Analytics on our website?</strong>
      </p>
      <p>
        Our goal with this website is clear: We want to offer you the best
        possible service. The statistics and data from Google Analytics help us
        to achieve this goal.
      </p>
      <p>
        The statistically evaluated data give us a clear picture of the
        strengths and weaknesses of our website. On the one hand, we can
        optimize our site so that it can be found more easily by interested
        people on Google. On the other hand, the data helps us to better
        understand you as a visitor. We therefore know exactly what we need to
        improve on our website in order to offer you the best possible service.
        The data also helps us to carry out our advertising and marketing
        measures more individually and cost-effectively. After all, it only
        makes sense to show our products and services to people who are
        interested.
      </p>
      <p>
        <strong>Which data is stored by Google Analytics?</strong>
      </p>
      <p>
        Google Analytics uses a tracking code to create a random, unique ID
        associated with your browser cookie. This is how Google Analytics
        recognizes you as a new user. The next time you visit our site, you will
        be recognized as a "returning" user. All collected data is stored
        together with this user ID. This makes it possible to evaluate
        pseudonymous user profiles.
      </p>
      <p>
        Labels such as cookies and app instance IDs measure your interactions on
        our site. Interactions are all types of actions you perform on our site.
        If you also use other Google systems (such as a Google account), data
        generated by Google Analytics may be linked to third-party cookies.
        Google does not pass on any Google Analytics data unless we as the
        website operator authorize it. Exceptions may be made if required by
        law.
      </p>
      <p>The following cookies are used by Google Analytics:</p>
      <p>
        <strong>Name:</strong> _ga
        <br /> <strong>Wert: 2.</strong>1326744211.152311202202-5
        <br /> <strong>Purpose:</strong> By default, analytics.js uses the _ga
        cookie to store the user ID. Basically, it is used to differentiate
        between website visitors.
        <br /> <strong>Expiration date:</strong> after 2 years
      </p>
      <p>
        <strong>Name:</strong> _gid
        <br /> <strong>Wert: 2.</strong>1687193234.152311202202-1
        <br /> <strong>Purpose:</strong> The cookie is also used to
        differentiate between website visitors.
        <br /> <strong>Expiration date:</strong> after 24 hours
      </p>
      <p>
        <strong>Name:</strong> _gat_gtag_UA_&lt; property-id&gt;
        <br /> <strong>value:</strong> 1<br /> <strong>Purpose:</strong> Used to
        lower the request rate. If Google Analytics is provided via the Google
        Tag Manager, this cookie is named _dc_gtm_ &lt; property-id&gt;.
        <br /> <strong>Expiration date: </strong>after 1 minute
      </p>
      <p>
        <strong>Name:</strong> AMP_TOKEN
        <br /> <strong>Value:</strong> not specified
        <br /> <strong>Purpose:</strong> The cookie has a token with which a
        user ID can be retrieved from the AMP Client ID Service. Other possible
        values indicate a logout, a request or an error.
        <br /> <strong>Expiration date:</strong> after 30 seconds up to one year
      </p>
      <p>
        <strong>Name:</strong> __utma
        <br /> <strong>Wert: </strong>1564498958.1564498958.1564498958.1
        <br /> <strong>Purpose:</strong> With this cookie you can track your
        behavior on the website and measure performance. The cookie is updated
        each time information is sent to Google Analytics.
        <br /> <strong>Expiration date:</strong> after 2 years
      </p>
      <p>
        <strong>Name:</strong> __utmt
        <br /> <strong>Value:</strong> 1<br /> <strong>Purpose:</strong> The
        cookie is used like _gat_gtag_UA_&lt; property-id&gt; to throttle the
        request rate.
        <br /> <strong>Expiration date:</strong> after 10 minutes
      </p>
      <p>
        <strong>Name:</strong> __utmb
        <br /> <strong>Value: </strong>3.10.1564498958
        <br /> <strong>Purpose:</strong> This cookie is used to determine new
        sessions. It is updated every time new data or information is sent to
        Google Analytics.
        <br /> <strong>Expiration date:</strong> after 30 minutes
      </p>
      <p>
        <strong>Name:</strong> __utmc
        <br /> <strong>Value:</strong> 167421564
        <br /> <strong>Purpose:</strong> This cookie is used to establish new
        sessions for returning visitors. This is a session cookie, and it is
        only stored until you close the browser.
        <br /> <strong>Expiration date:</strong> After closing the browser
      </p>
      <p>
        <strong>Name:</strong> __utmz
        <br /> <strong>Value:</strong>{" "}
        m|utmccn=(referral)|utmcmd=referral|utmcct=/
        <br /> <strong>Purpose:</strong> The cookie is used to identify the
        source of traffic to our website. This means that the cookie stores
        where you came to our website from. This may have been another site or
        an advertising campaign.
        <br /> <strong>Expiration date:</strong> after 6 months
      </p>
      <p>
        <strong>Name:</strong> __utmv
        <br /> <strong>Value:</strong> not specified
        <br /> <strong>Purpose:</strong> The cookie is used to store
        user-defined user data. It is always updated when information is sent to
        Google Analytics.
        <br /> <strong>Expiration date:</strong> after 2 years
      </p>
      <p>
        <strong>Note:</strong> This list cannot claim to be complete, as Google
        constantly changes the choice of its cookies.
      </p>
      <p>
        Here we show you an overview of the most important data collected with
        Google Analytics:
      </p>
      <p>
        <strong>heat maps: </strong>Google creates so-called heat maps. With
        Heatmaps you can see exactly those areas that you click on. This way we
        get information where you are "on the road" on our site.
      </p>
      <p>
        <strong>Session duration:</strong> Google defines session duration as
        the time you spend on our site without leaving the site. If you have
        been inactive for 20 minutes, the session ends automatically.
      </p>
      <p>
        Bouncerate: A bouncer is when you view only one page on our website and
        then leave our website.
      </p>
      <p>
        <strong>Account creation:</strong> When you create an account or place
        an order on our website, Google Analytics collects this information.
      </p>
      <p>
        <strong>IP address:</strong> The IP address is only shown in abbreviated
        form, so that no clear assignment is possible.
      </p>
      <p>
        <strong>location:</strong> The IP address can be used to determine the
        country and your approximate location. This process is also called IP
        location determination.
      </p>
      <p>
        <strong>Technical Information:</strong> Technical information includes
        your browser type, Internet service provider, and screen resolution.
      </p>
      <p>
        <strong>Source of origin:</strong> Google Analytics or us. Of course, we
        are also interested in which website or which advertisement brought you
        to our site.
      </p>
      <p>
        Other data includes contact information, any ratings, playing media
        (e.g. when you play a video on our site), sharing content via social
        media or adding to your favorites. This list does not claim to be
        exhaustive and serves only as a general orientation for data storage by
        Google Analytics.
      </p>
      <p>
        <strong>How long and where is the data stored?</strong>
      </p>
      <p>
        Google has distributed your servers around the world. Most servers are
        located in America and therefore your data is usually stored on American
        servers. Here you can find out exactly where the Google data centers are
        located:{" "}
        <a href="https://www.google.com/about/datacenters/inside/locations/?hl=de">
          https://www.google.com/about/datacenters/inside/locations/?hl=de
          <span class="Apple-converted-space">&nbsp;</span>
        </a>
      </p>
      <p>
        Your data is distributed on different physical media. This has the
        advantage that the data can be retrieved more quickly and is better
        protected against manipulation. In every Google data center there are
        appropriate emergency programs for your data. If, for example, Google's
        hardware fails or natural disasters paralyze servers, the risk of a
        service interruption at Google remains low.
      </p>
      <p>
        Google Analytics has a standard retention period of 26 months for your
        user data. Then your user data will be deleted. However, we do have the
        option to choose the retention period of user data ourselves. We have
        five options for this:
      </p>
      <ul>
        <li>Deletion after 14 months</li>
        <li>Deletion after 26 months</li>
        <li>Deletion after 38 months</li>
        <li>Deletion after 50 months</li>
        <li>No automatic deletion</li>
      </ul>
      <p>
        When the specified period has expired, the data is deleted once a month.
        This retention period applies to your data associated with cookies, user
        recognition and advertising IDs (e.g., DoubleClick domain cookies).
        Reporting results are based on aggregated data and are stored
        independently of user data. Aggregated data is a fusion of individual
        data into a larger unit.
      </p>
      <p>
        <strong>How can I delete my data or prevent data storage?</strong>
      </p>
      <p>
        According to the data protection law of the European Union, you have the
        right to obtain information about your data, update it, delete it or
        restrict it. By using the browser add-on to deactivate Google Analytics
        JavaScript (ga.js, analytics.js, dc.js) you prevent Google Analytics
        from using your data. You can download and install the browser add-on at{" "}
        <a href="https://tools.google.com/dlpage/gaoptout?hl=de">
          https://tools.google.com/dlpage/gaoptout?hl=de.
        </a>{" "}
        Please note that this add-on only disables the data collection by Google
        Analytics.
      </p>
      <p>
        If you basically want to deactivate, delete or manage cookies
        (independent of Google Analytics), there are separate instructions for
        each browser:
      </p>
      <p>
        <a href="https://support.google.com/chrome/answer/95647?tid=311202202">
          Chrome: Delete, activate and manage cookies in Chrome
          <span class="Apple-converted-space">&nbsp;</span>
        </a>
      </p>
      <p>
        <a href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=311202202">
          Safari: Manage cookies and website data with Safari
          <span class="Apple-converted-space">&nbsp;</span>
        </a>
      </p>
      <p>
        <a href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=311202202">
          Firefox: delete cookies to remove data that websites have placed on
          your computer<span class="Apple-converted-space">&nbsp;</span>
        </a>
      </p>
      <p>
        <a href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=311202202">
          Internet Explorer: Delete and manage cookies
          <span class="Apple-converted-space">&nbsp;</span>
        </a>
      </p>
      <p>
        <a href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=311202202">
          Microsoft Edge: Delete and manage cookies
          <span class="Apple-converted-space">&nbsp;</span>
        </a>
      </p>
      <p>
        Google Analytics is an active participant in the EU-U.S. Privacy Shield
        Framework, which regulates the correct and secure transfer of personal
        data. You can find more information about this at{" "}
        <a href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI">
          https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;tid=311202202.
        </a>{" "}
        We hope we were able to provide you with the most important information
        about data processing by Google Analytics. If you want to learn more
        about the tracking service, we recommend these two links:{" "}
        <a href="http://www.google.com/analytics/terms/de.html">
          http://www.google.com/analytics/terms/de.html
        </a>{" "}
        and{" "}
        <a href="https://support.google.com/analytics/answer/6004245?hl=de">
          https://support.google.com/analytics/answer/6004245?hl=de.
        </a>
      </p>
      <p>
        <strong>Google Analytics IP anonymization</strong>
      </p>
      <p>
        We have implemented the IP address anonymization of Google Analytics on
        this website. This function was developed by Google so that this website
        can comply with the applicable data protection regulations and
        recommendations of local data protection authorities if they prohibit
        the storage of the complete IP address. IP addresses are anonymized or
        masked as soon as the IP addresses arrive in the Google Analytics data
        collection network and before the data is saved or processed.
      </p>
      <p>
        More information on IP anonymization can be found at{" "}
        <a href="https://support.google.com/analytics/answer/2763052?hl=de">
          https://support.google.com/analytics/answer/2763052?hl=de.
        </a>
      </p>
      <p>
        <strong>
          Google Analytics reports on demographic characteristics and interests
        </strong>
      </p>
      <p>
        We have enabled the advertising reporting features in Google Analytics.
        The demographic and interest reports include information on age, gender
        and interests. This enables us to get a better picture of our users
        without having to assign this data to individual persons. You can learn
        more about the advertising features{" "}
        <a href="https://support.google.com/analytics/answer/3450482?hl=de_AT&amp;utm_id=ad">
          at
          https://support.google.com/analytics/answer/3450482?hl=de_AT&amp;utm_id=ad.
        </a>
      </p>
      <p>
        You can stop using the activities and information of your Google Account
        by checking the checkbox under "Advertising settings" on{" "}
        <a href="https://adssettings.google.com/authenticated">
          https://adssettings.google.com/authenticated.
        </a>
      </p>
      <p>
        <strong>Google Analytics add-on for data processing</strong>
      </p>
      <p>
        We have entered into a direct customer agreement with Google for the use
        of Google Analytics by accepting the "Data Processing Addendum" in
        Google Analytics.
      </p>
      <p>
        More about the data processing add-on for Google Analytics can be found
        here:{" "}
        <a href="https://support.google.com/analytics/answer/3379636?hl=de&amp;utm_id=ad">
          https://support.google.com/analytics/answer/3379636?hl=de&amp;utm_id=ad
          <span class="Apple-converted-space">&nbsp;</span>
        </a>
      </p>
      <p>
        <strong>Newsletter Privacy Policy</strong>
      </p>
      <p>
        If you register for our newsletter, you provide the above mentioned
        personal data and give us the right to contact you by e-mail. We use the
        data stored during the registration for the newsletter exclusively for
        our newsletter and do not pass them on.
        <span class="Apple-converted-space">&nbsp;</span>
      </p>
      <p>
        If you unsubscribe from the newsletter - you will find the link for this
        at the bottom of every newsletter - we will delete all data that was
        saved when you registered for the newsletter.
        <span class="Apple-converted-space">&nbsp;</span>
      </p>
      <p>
        <strong>Embedded Social Media Elements Privacy Policy</strong>
      </p>
      <p>
        We integrate elements of social media services on our website to display
        images, videos and text. When you
        <br /> <span class="Apple-converted-space">&nbsp;</span>visit pages that
        display these elements, data is transferred
        <br /> <span class="Apple-converted-space">&nbsp;</span>from your
        browser to the respective social media service and stored there. We have
        no access to this data. <br /> The following links will take you to the
        pages of the respective social media services where it is explained how
        they handle your data:
      </p>
      <ul>
        <li>
          Instagram Privacy Policy:{" "}
          <a href="https://help.instagram.com/519522125107875?tid=311202202">
            https://help.instagram.com/519522125107875
            <span class="Apple-converted-space">&nbsp;</span>
          </a>
        </li>
        <li>
          For YouTube the Google Privacy Policy applies:{" "}
          <a href="https://policies.google.com/privacy?hl=de&amp;tid=311202202">
            https://policies.google.com/privacy?hl=de
            <span class="Apple-converted-space">&nbsp;</span>
          </a>
        </li>
        <li>
          Facebook data policy:{" "}
          <a href="https://www.facebook.com/about/privacy?tid=311202202">
            https://www.facebook.com/about/privacy
            <span class="Apple-converted-space">&nbsp;</span>
          </a>
        </li>
        <li>
          Twitter Privacy Policy:
          <a href="https://twitter.com/de/privacy?tid=311202202">
            {" "}
            https://twitter.com/de/privacy
            <span class="Apple-converted-space">&nbsp;</span>
          </a>
        </li>
      </ul>
      <p>
        <strong>Twitter Privacy Policy</strong>
      </p>
      <p>
        On our website we have integrated functions of Twitter. These are for
        example embedded tweets, timelines, buttons or hashtags. Twitter is a
        short message service and social media platform of the company Twitter
        Inc., One Cumberland Place, Fenian Street, Dublin 2 D02 AX07, Ireland.
      </p>
      <p>
        To our knowledge, in the European Economic Area and in Switzerland, the
        mere inclusion of Twitter function does not yet transfer any personal
        data or data concerning your web activities to Twitter. Only when you
        interact with the Twitter functions, such as clicking a button, can data
        be sent to Twitter, stored and processed there. We have no influence on
        and are not responsible for this data processing. Within the scope of
        this privacy policy, we would like to give you an overview of the data
        Twitter stores, what Twitter does with this data and how you can largely
        protect yourself from data transfer.
      </p>
      <p>
        <strong>What is Twitter?</strong>
      </p>
      <p>
        For some, Twitter is a news service, for others a social media platform
        and yet others speak of a microblogging service. All these terms have
        their justification and mean more or less the same thing.
      </p>
      <p>
        Both private individuals and companies use Twitter to communicate with
        interested people via short messages. Twitter only allows 280 characters
        per message. These messages are called "tweets". Unlike Facebook, for
        example, the service does not focus on expanding a network for
        "friends", but wants to be understood as a worldwide and open message
        platform. With Twitter, it is also possible to have an anonymous account
        and tweets can be deleted by the company on the one hand and by the
        users themselves on the other.
      </p>
      <p>
        <strong>Why do we use Twitter on our website?</strong>
      </p>
      <p>
        Like many other websites and companies, we try to offer our services
        through different channels and communicate with our customers. Twitter
        in particular has grown dear to our heart as a useful "little" news
        service. Again and again we tweet or retweet exciting, funny or
        interesting content. We are aware that you cannot follow every channel
        separately. After all, you also have something else to do. That's why we
        have also integrated Twitter functions on our website. You can
        experience our Twitter activity "on site" or come to our Twitter page
        via a direct link. Through the integration we want to strengthen our
        service and the user-friendliness on our website.
      </p>
      <p>
        <strong>What data is stored by Twitter?</strong>
      </p>
      <p>
        On some of our subpages you will find the built-in Twitter functions.
        When you interact with Twitter content, such as clicking a button,
        Twitter can collect and store data. Even if you do not have a Twitter
        account yourself. Twitter calls this data "log data". This includes
        demographic data, browser cookie IDs, the ID of your smartphone, hashed
        email addresses, and information about which pages you visited on
        Twitter and what actions you took. Of course, Twitter stores more data
        if you have a Twitter account and are logged in. Most of the time this
        storage is done via cookies. Cookies are small text files that are
        usually set in your browser and transmit various information to Twitter.
      </p>
      <p>
        We will now show you which cookies are set when you are not logged in to
        Twitter but visit a website with built-in Twitter functions. Please
        consider this enumeration as an example. We can in no way guarantee
        completeness here, as the choice of cookies is always changing and
        depends on your individual actions with the Twitter content.
      </p>
      <p>These cookies were used in our test:</p>
      <p>
        <strong>Name:</strong> personalization_id
        <br /> <strong>Wert: "</strong>v1_cSJIsogU51SeE311202202"
        <br /> <strong>Purpose: </strong>This cookie stores information on how
        you use the website and which advertisements you may have used to come
        to Twitter.
        <br /> <strong>Expiration date:</strong> after 2 years
      </p>
      <p>
        <strong>Name: </strong>long
        <br /> <strong>Value: </strong>de
        <br /> <strong>Purpose: </strong>This cookie stores your preset or
        preferred language.
        <br /> <strong>Expiration date: </strong>after session end
      </p>
      <p>
        <strong>Name: </strong>guest_id
        <br /> <strong>Value: </strong>311202202v1%3A157132626
        <br /> <strong>Purpose: </strong>This cookie is set to identify you as a
        guest.<span class="Apple-converted-space">&nbsp; </span>
        <br /> <strong>Expiration date: </strong>after 2 years
      </p>
      <p>
        <strong>Name: </strong>fm
        <br /> <strong>Value: </strong>0<br /> <strong>Purpose: </strong>
        Unfortunately we could not find out the purpose of this cookie.
        <br /> <strong>Expiration date: </strong>after the end of the session
      </p>
      <p>
        <strong>Name: </strong>external_referer
        <br /> <strong>Wert: </strong>3112022022beTA0sf5lkMrlGt
        <br /> <strong>Purpose: </strong>This cookie collects anonymous data,
        such as how often you visit Twitter and how long you visit Twitter.
        <br /> <strong>Expiration date: </strong>After 6 days
      </p>
      <p>
        <strong>Name: </strong>eu_cn
        <br /> <strong>Value: </strong>1<br /> <strong>Purpose: </strong>This
        cookie stores user activity and serves various advertising purposes of
        Twitter.{" "}
        <strong>
          <br /> Expiration date:{" "}
        </strong>
        After one year
      </p>
      <p>
        <strong>Name: </strong>ct0
        <br /> <strong>Wert: </strong>c1179f07163a365d2ed7aad84c99d966
        <br /> <strong>Purpose: </strong>Unfortunately we have not found any
        information about this cookie.
        <br /> <strong>Expiration date: </strong>after 6 hours
      </p>
      <p>
        <strong>Name: </strong>_twitter_sess
        <br /> <strong>Wert: </strong>53D%253D-dd0248311202202-
        <br /> <strong>Purpose: </strong>With this cookie you can use functions
        within the Twitter website.
        <br /> <strong>Expiration date: </strong>after session end
      </p>
      <p>
        <strong>Note:</strong> Twitter also works with third party providers.
        That's why our test also recognized the three Google Analytics cookies
        _ga, _gat, _gid.
      </p>
      <p>
        Twitter uses the collected data on the one hand to better understand
        user behavior and thus improve its own services and advertising offers,
        and on the other hand the data also serves internal security measures.
      </p>
      <p>
        <strong>How long and where is the data stored?</strong>
      </p>
      <p>
        If Twitter collects data from other websites, it will be deleted,
        aggregated or otherwise concealed after a maximum of 30 days. The
        Twitter servers are located at different server centers in the United
        States. It can therefore be assumed that collected data is collected and
        stored in America. After our research we could not clearly determine
        whether Twitter also has its own servers in Europe. In principle,
        Twitter can store the collected data until it is no longer useful to the
        company, you delete the data or a legal deadline for deletion exists.
      </p>
      <p>
        <strong>How can I delete my data or prevent data storage?</strong>
      </p>
      <p>
        In its privacy policy, Twitter repeatedly emphasizes that it does not
        store any data from external website visits if you or your browser is
        located in the European Economic Area or in Switzerland. However, if you
        interact directly with Twitter, Twitter will of course also store data
        about you.
      </p>
      <p>
        If you have a Twitter account, you can manage your data by clicking
        "More" under the "Profile" button. Then click on "Settings and Privacy".
        Here you can manage your data individually.
      </p>
      <p>
        If you don't have a Twitter account, you can go to{" "}
        <a href="https://twitter.com/">twitter.com</a> and then click on
        "Individualization". Under the point "Individualization and data" you
        can manage your collected data.
      </p>
      <p>
        Most of the data, as mentioned above, is stored via cookies and you can
        manage, deactivate or delete them in your browser. Please note that you
        can only "edit" the cookies in the browser you have selected. This
        means: if you use another browser in the future, you will have to manage
        your cookies there again according to your wishes. Here you can find the
        instructions for cookie management of the most popular browsers.
      </p>
      <p>
        <a href="https://support.google.com/chrome/answer/95647?tid=311202202">
          Chrome: Delete, activate and manage cookies in Chrome
          <span class="Apple-converted-space">&nbsp;</span>
        </a>
      </p>
      <p>
        <a href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=311202202">
          Safari: Manage cookies and website data with Safari
          <span class="Apple-converted-space">&nbsp;</span>
        </a>
      </p>
      <p>
        <a href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=311202202">
          Firefox: delete cookies to remove data that websites have placed on
          your computer<span class="Apple-converted-space">&nbsp;</span>
        </a>
      </p>
      <p>
        <a href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=311202202">
          Internet Explorer: Delete and manage cookies
          <span class="Apple-converted-space">&nbsp;</span>
        </a>
      </p>
      <p>
        <a href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=311202202">
          Microsoft Edge: Delete and manage cookies
          <span class="Apple-converted-space">&nbsp;</span>
        </a>
      </p>
      <p>
        You can also manage your browser in such a way that you are informed
        about every single cookie. Then you can always decide individually
        whether you want to accept a cookie or not.
      </p>
      <p>
        Twitter also uses the data for personalized advertising inside and
        outside Twitter. You can turn off personalized advertising in the
        settings under "Individualization and data". If you use Twitter on a
        browser, you can{" "}
        <a href="http://optout.aboutads.info/?c=2&amp;lang=EN">deactivate</a>{" "}
        personalized advertising at{" "}
        <a href="http://optout.aboutads.info/?c=2&amp;lang=EN">
          http://optout.aboutads.info/?c=2&amp;lang=EN.
        </a>
      </p>
      <p>
        Twitter is an active participant in the EU-U.S. Privacy Shield
        Framework. This framework ensures correct data transmission between the
        USA and the European Union. You can learn more about it at{" "}
        <a href="https://www.privacyshield.gov/participant?id=a2zt0000000TORzAAO">
          https://www.privacyshield.gov/participant?id=a2zt0000000TORzAAO.
        </a>
      </p>
      <p>
        We hope we have given you a basic overview of the data processing by
        Twitter. We do not receive any data from Twitter and are not responsible
        for what Twitter does with your data. If you have any further questions
        on this topic, we recommend the Twitter privacy policy at{" "}
        <a href="https://twitter.com/de/privacy">
          https://twitter.com/de/privacy.
        </a>
      </p>
      <p>
        <strong>LinkedIn Privacy Policy</strong>
      </p>
      <p>
        On our website we use social plug-ins from the social media network
        LinkedIn, the company LinkedIn Corporation, 2029 Stierlin Court,
        Mountain View, CA 94043, USA. The social plug-ins can be feeds, content
        sharing or linking to our LinkedIn site. The social plug-ins are clearly
        marked with the well-known LinkedIn logo and allow, for example, to
        share interesting content directly through our website. For the European
        Economic Area and Switzerland, LinkedIn Ireland Unlimited Company Wilton
        Place in Dublin is responsible for data processing.
      </p>
      <p>
        By embedding such plug-ins, data can be sent to LinkedIn, stored and
        processed there. In this privacy policy we would like to inform you
        which data is involved, how the network uses this data and how you can
        manage or stop the data storage.
      </p>
      <p>
        <strong>What is LinkedIn?</strong>
      </p>
      <p>
        LinkedIn is the largest social network for business contacts. Unlike
        Facebook, for example, the company focuses exclusively on building
        business contacts. Companies can present services and products on the
        platform and establish business relationships. Many people also use
        LinkedIn for job search or to find suitable employees for their own
        company. In Germany alone, the network has over 11 million members. In
        Austria there are about 1.3 million.
      </p>
      <p>
        <strong>Why do we use LinkedIn on our website?</strong>
      </p>
      <p>
        We know how busy you are. You can't watch all social media channels one
        by one. Even if, as in our case, it would be worth it. Because we always
        post interesting news or reports that are worth spreading. That's why we
        have created the possibility on our website to share interesting content
        directly on LinkedIn or to link directly to our LinkedIn page. We
        consider built-in social plug-ins as an extended service on our website.
        The information LinkedIn collects also helps us to show possible
        advertising to people who are interested in our services.
      </p>
      <p>
        <strong>What data is stored by LinkedIn?</strong>
      </p>
      <p>
        Simply by integrating the social plug-ins, LinkedIn does not store any
        personal data. LinkedIn calls this data, which is generated by plug-ins,
        passive impressions. However, if you click on a social plug-in, for
        example to share our content, the platform stores personal data as
        so-called "active impressions". This is the case regardless of whether
        you have a LinkedIn account or not. If you are logged in, the collected
        data is assigned to your account.
      </p>
      <p>
        Your browser connects directly to LinkedIn's servers when you interact
        with our plug-ins. This is how the company logs various usage data. In
        addition to your IP address, this may include login information, device
        information, or information about your Internet or mobile phone
        provider. If you access LinkedIn services from your smartphone, your
        location (after you allow it) can also be determined. LinkedIn may also
        share this information in "hashed" form with third-party advertisers.
        Hashing means that a record is converted into a string. This makes it
        possible to encrypt the data in a way that people can no longer be
        identified.
      </p>
      <p>
        Most data on your user behavior is stored in cookies. These are small
        text files that are usually set in your browser. However, LinkedIn may
        also use web beacons, pixel tags, display tags and other device
        identifiers.
      </p>
      <p>
        Various tests also show which cookies are set when a user interacts with
        a social plug-in. The data found cannot claim to be complete and is only
        used as an example. The following cookies were set without being logged
        in to LinkedIn:
      </p>
      <p>
        <strong>Name:</strong> bcookie
        <br /> <strong>Wert:</strong>{" "}
        =2&amp;34aab2aa-2ae1-4d2a-8baf-c2e2d7235c16311202202-
        <br /> <strong>Purpose:</strong> The cookie is a so-called "browser ID
        cookie" and thus stores your identification number (ID).
        <br /> <strong>Expiration date:</strong> After 2 years
      </p>
      <p>
        <strong>Name:</strong> long
        <br /> <strong>Value:</strong> v=2&amp;long=en-en
        <br /> <strong>Purpose:</strong> This cookie stores your preset or
        preferred language.
        <br /> <strong>Expiration date:</strong> after the end of the session
      </p>
      <p>
        <strong>Name:</strong> lidc
        <br /> <strong>Wert:</strong>{" "}
        1818367:t=1571904767:s=AQF6KNnJ0G311202202...
        <br /> <strong>Purpose:</strong> This cookie is used for routing.
        Routing records how you got to LinkedIn and how you navigate through the
        site.
        <br /> <strong>Expiration date:</strong> after 24 hours
      </p>
      <p>
        <strong>Name:</strong> rtc
        <br /> <strong>Value:</strong> kt0lrv3NF3x3t6xvDgGrZGDKkX
        <br /> <strong>Purpose:</strong> No further information could be found
        out about this cookie.
        <br /> <strong>Expiration date:</strong> after 2 minutes
      </p>
      <p>
        <strong>Name:</strong> JSESSIONID
        <br /> <strong>Wert: ajax</strong>:3112022022900777718326218137
        <br /> <strong>Purpose: This is</strong> a session cookie that LinkedIn
        uses to maintain anonymous user sessions through the server.
        <br /> <strong>Expiration date:</strong> after the end of the session
      </p>
      <p>
        <strong>Name:</strong> bscookie
        <br /> <strong>Value:</strong> "v=1&amp;201910230812...
        <br /> <strong>Purpose:</strong> This cookie is a security cookie.
        LinkedIn describes it as a secure browser ID cookie.
        <br /> <strong>Expiration date:</strong> after 2 years
      </p>
      <p>
        <strong>Name:</strong> fid
        <br /> <strong>Value:</strong> AQHj7Ii23ZBcqAAAA...
        <br /> <strong>Purpose:</strong> No further information could be found
        for this cookie.
        <br /> <strong>Expiration date:</strong> after 7 days
      </p>
      <p>
        <strong>Note:</strong> LinkedIn also works with third party providers.
        That's why we recognized the two Google Analytics cookies _ga and _gat
        during our test.
      </p>
      <p>
        <strong>How long and where is the data stored?</strong>
      </p>
      <p>
        Generally, LinkedIn will retain your personal information for as long as
        the company considers it necessary to provide its services. However,
        LinkedIn will delete your personal information if you delete your
        account. In some exceptional cases, LinkedIn may retain some information
        in aggregate and anonymous form even after you delete your account. Once
        you delete your account, other people will not be able to see your
        information within one day. LinkedIn always deletes the data within 30
        days. LinkedIn does, however, retain data if required to do so by law.
        Data that can no longer be assigned to any person remains stored even
        after the account has been closed. The data is stored on different
        servers in America and probably also in Europe.
      </p>
      <p>
        <strong>How can I delete my data or prevent data storage?</strong>
      </p>
      <p>
        You have the right to access and delete your personal data at any time.
        In your LinkedIn account you can manage, modify and delete your data.
        You may also request a copy of your personal information from LinkedIn.
      </p>
      <p>How to access the account information in your LinkedIn profile:</p>
      <p>
        In LinkedIn, click on your profile icon and select the "Settings and
        Privacy" section. Now click on "Privacy" and then in the section "How
        LinkedIn uses your data" click on "Change". In a short time, you will be
        able to download selected information about your web activity and
        account history.
      </p>
      <p>
        You also have the option of preventing LinkedIn from processing data in
        your browser. As mentioned above, LinkedIn stores most of its data
        through cookies that are set in your browser. You can manage, disable or
        delete these cookies. Depending on which browser you have, the
        administration works slightly differently. The instructions for the most
        common browsers can be found here:
      </p>
      <p>
        <a href="https://support.google.com/chrome/answer/95647?tid=311202202">
          Chrome: Delete, activate and manage cookies in Chrome
          <span class="Apple-converted-space">&nbsp;</span>
        </a>
      </p>
      <p>
        <a href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=311202202">
          Safari: Manage cookies and website data with Safari
          <span class="Apple-converted-space">&nbsp;</span>
        </a>
      </p>
      <p>
        <a href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=311202202">
          Firefox: delete cookies to remove data that websites have placed on
          your computer<span class="Apple-converted-space">&nbsp;</span>
        </a>
      </p>
      <p>
        <a href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=311202202">
          Internet Explorer: Delete and manage cookies
          <span class="Apple-converted-space">&nbsp;</span>
        </a>
      </p>
      <p>
        <a href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=311202202">
          Microsoft Edge: Delete and manage cookies
          <span class="Apple-converted-space">&nbsp;</span>
        </a>
      </p>
      <p>
        You can also set up your browser so that you are always informed when a
        cookie is to be set. Then you can always decide individually whether you
        want to accept the cookie or not.
      </p>
      <p>
        LinkedIn is an active participant of the EU-U.S. Privacy Shield
        Framework. This framework ensures a correct data transfer between the
        USA and the European Union. You can learn more about it at{" "}
        <a href="https://www.privacyshield.gov/participant?id=a2zt0000000L0UZAA0">
          https://www.privacyshield.gov/participant?id=a2zt0000000L0UZAA0.
        </a>{" "}
        We have tried to provide you with the most important information about
        data processing by LinkedIn. At{" "}
        <a href="https://www.linkedin.com/legal/privacy-policy">
          https://www.linkedin.com/legal/privacy-policy you can learn more
        </a>{" "}
        about the data processing of the social media network LinkedIn.
      </p>
      <p>
        <strong>XING Privacy Policy</strong>
      </p>
      <p>
        On our website we use social plugins of the social media network Xing,
        the company Xing SE, Dammtorstra&szlig;e 30, 20354 Hamburg, Germany.
        These features allow you, for example, to share content on Xing directly
        via our website, log in via Xing or follow interesting content. You can
        recognize the plug-ins by the company name or the Xing logo. When you
        visit a website that uses a Xing plug-in, data can be transmitted to the
        "Xing servers", stored and evaluated. In this privacy policy we would
        like to inform you about what data is involved and how you can manage or
        prevent this data storage.
      </p>
      <p>
        <strong>What is Xing?</strong>
      </p>
      <p>
        Xing is a social network with its headquarters in Hamburg. The company
        specializes in the management of professional contacts. That means,
        unlike other networks, Xing is primarily about professional networking.
        The platform is often used for job search or to find employees for your
        own company. In addition, Xing offers interesting content on various
        professional topics. The global counterpart is the American company
        LinkedIn.
      </p>
      <p>
        <strong>Why do we use Xing on our website?</strong>
      </p>
      <p>
        There is now a flood of social media channels and we are well aware that
        your time is very precious. Not every social media channel of a company
        can be closely examined. That's why we want to make life as easy as
        possible for you so that you can share or follow interesting content
        directly on Xing through our website. With such "social plug-ins" we are
        expanding our service on our website. Furthermore, the data collected by
        Xing helps us to carry out targeted advertising measures on the
        platform. This means that our service is only shown to people who are
        really interested in it.
      </p>
      <p>
        <strong>What data is stored by Xing?</strong>
      </p>
      <p>
        Xing offers the Share button, Follow button and Log-in button as a
        plug-in for websites. As soon as you open a page where a social plug-in
        from Xing is installed, your browser connects to servers in a data
        center used by Xing. In the case of the Share button, according to Xing,
        no data should be stored that could derive a direct reference to a
        person. In particular, Xing does not store any IP address of yours.
        Furthermore, no cookies are set in connection with the Share button.
        Therefore, no evaluation of your user behavior will take place. You can
        obtain further information about this at{" "}
        <a href="https://www.xing.com/app/share%253Fop%253Ddata_protection?tid=311202202">
          https://www.xing.com/app/share%3Fop%3Ddata_protection.
          <span class="Apple-converted-space">&nbsp;</span>
        </a>
      </p>
      <p>
        With the other Xing plug-ins, cookies are only set in your browser when
        you interact with the plug-in or click on it. Personal data such as your
        IP address, browser data, date and time of your page view at Xing can be
        stored here. If you have a XING account and are logged in, collected
        data will be assigned to your personal account and the data stored in
        it.
      </p>
      <p>
        The following cookies are set in your browser when you click on the
        follow or log-in button and are not yet logged in to Xing. Please note
        that this is an exemplary list and that we cannot claim to be complete:
      </p>
      <p>
        <strong>Name:</strong> AMCVS_0894FF2554F733210A4C98C6%40AdobeOrg
        <br /> <strong>Value:</strong> 1<br /> <strong>Purpose:</strong> This
        cookie is used to create and store identification of website visitors.
        <br /> <strong>Expiration date:</strong> after session end
      </p>
      <p>
        <strong>Name:</strong> c_
        <br /> <strong>Wert:</strong>{" "}
        157c609dc9fe7d7ff56064c6de87b019311202202-8
        <br /> <strong>Purpose:</strong> We were not able to find out more
        information about this cookie.
        <br /> <strong>Expiration date:</strong> after one day
      </p>
      <p>
        <strong>Name:</strong> prevPage
        <br /> <strong>Value:</strong> wbm%2FWelcome%2Flogin
        <br /> <strong>Purpose:</strong> This cookie stores the URL of the
        previous website you visited.
        <br /> <strong>Expiration date:</strong> after 30 minutes
      </p>
      <p>
        <strong>Name:</strong> s_cc
        <br /> <strong>Value:</strong> true
        <br /> <strong>Purpose:</strong> This Adobe Site Catalyst cookie
        determines whether cookies are always enabled in the browser.
        <br /> <strong>Expiration date:</strong> after the end of the session
      </p>
      <p>
        <strong>Name:</strong> s_fid
        <br /> <strong>Wert:</strong>{" "}
        6897CDCD1013221C-39DDACC982217CD1311202202-2
        <br /> <strong>Purpose:</strong> This cookie is used to identify a
        unique visitor.
        <br /> <strong>Expiration date:</strong> after 5 years
      </p>
      <p>
        <strong>Name:</strong> visitor_id
        <br /> <strong>Wert:</strong> fe59fbe5-e9c6-4fca-8776-30d0c1a89c32
        <br /> <strong>Purpose:</strong> The visitor cookie contains a unique
        visitor ID and the unique identifier for your account.
        <br /> <strong>Expiration date:</strong> after 2 years
      </p>
      <p>
        <strong>Name:</strong>_session_id
        <br /> <strong>Wert: </strong>
        533a0a6641df82b46383da06ea0e84e7311202202-2
        <br /> <strong>Purpose:</strong> This cookie creates a temporary session
        ID that is used as an in-session user ID. The cookie is absolutely
        necessary to provide the functions of Xing.
        <br /> <strong>Expiration date:</strong> after session end
      </p>
      <p>
        As soon as you log in to Xing or become a member, further personal data
        will definitely be collected, processed and stored. Xing also passes on
        personal data to third parties if this is necessary for the fulfilment
        of its own business purposes, if you have given your consent or if there
        is a legal obligation to do so.
      </p>
      <p>
        <strong>How long and where is the data stored?</strong>
      </p>
      <p>
        Xing stores the data on different servers in different data centers. The
        company stores this data until you delete the data or until a user
        account is deleted. This of course only affects users who are already
        Xing members.
      </p>
      <p>
        <strong>How can I delete my data or prevent data storage?</strong>
      </p>
      <p>
        You have the right to access and delete your personal data at any time.
        Even if you are not a Xing member, you can use your browser to prevent
        possible data processing or manage it according to your wishes. Most
        data is stored via cookies. Depending on which browser you have, the
        administration works slightly differently. The instructions for the most
        common browsers can be found here:
      </p>
      <p>
        <a href="https://support.google.com/chrome/answer/95647?tid=311202202">
          Chrome: Delete, activate and manage cookies in Chrome
          <span class="Apple-converted-space">&nbsp;</span>
        </a>
      </p>
      <p>
        <a href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=311202202">
          Safari: Manage cookies and website data with Safari
          <span class="Apple-converted-space">&nbsp;</span>
        </a>
      </p>
      <p>
        <a href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=311202202">
          Firefox: delete cookies to remove data that websites have placed on
          your computer<span class="Apple-converted-space">&nbsp;</span>
        </a>
      </p>
      <p>
        <a href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=311202202">
          Internet Explorer: Delete and manage cookies
          <span class="Apple-converted-space">&nbsp;</span>
        </a>
      </p>
      <p>
        <a href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=311202202">
          Microsoft Edge: Delete and manage cookies
          <span class="Apple-converted-space">&nbsp;</span>
        </a>
      </p>
      <p>
        You can also set up your browser so that you are always informed when a
        cookie is to be set. Then you can always decide individually whether you
        want to accept the cookie or not.
      </p>
      <p>
        We have tried to provide you with the most important information about
        data processing by Xing. At https://privacy.xing.com/de/dat
        <a href="https://privacy.xing.com/de/datenschutzerklaerung?tid=311202202">
          enschutzerklaerung
        </a>{" "}
        you can learn more about the data processing of the social media network
        Xing.
      </p>
      <p>
        <strong>YouTube Privacy Policy</strong>
      </p>
      <p>
        We have included YouTube videos on our website. So we can present you
        interesting videos directly on our site. YouTube is a video portal,
        which is a subsidiary of Google since 2006. The video portal is operated
        by YouTube, LLC, 901 Cherry Ave., San Bruno, CA 94066, USA. When you
        visit a page on our website that has an embedded YouTube video, your
        browser automatically connects to the servers of YouTube or Google.
        Various data is transferred (depending on the settings). Google Ireland
        Limited (Gordon House, Barrow Street Dublin 4, Ireland) is responsible
        for all data processing in Europe.
      </p>
      <p>
        In the following, we would like to explain to you in more detail what
        data is processed, why we have included YouTube videos and how you can
        manage or delete your data.
      </p>
      <p>
        <strong>What is YouTube?</strong>
      </p>
      <p>
        On YouTube, users can watch, rate, comment and upload videos for free.
        Over the last few years, YouTube has become one of the most important
        social media channels worldwide. To enable us to display videos on our
        website, YouTube provides a code snippet that we have integrated into
        our site.
      </p>
      <p>
        <strong>Why do we use YouTube videos on our website?</strong>
      </p>
      <p>
        YouTube is the video platform with the most visitors and the best
        content. We strive to provide you with the best possible user experience
        on our website. And of course, interesting videos should not be missing.
        With the help of our embedded videos, we provide you with further
        helpful content in addition to our texts and images. In addition, the
        embedded videos make our website easier to find on the Google search
        engine. Even if we place advertisements via Google Ads, Google can -
        thanks to the data collected - really only show these ads to people who
        are interested in our offers.
      </p>
      <p>
        <strong>What data is stored by YouTube?</strong>
      </p>
      <p>
        When you visit one of our sites that has a YouTube video embedded,
        YouTube at least sets a cookie that stores your IP address and our URL.
        If you are logged in to your YouTube account, YouTube can associate your
        interactions on our site with your profile, usually by using cookies.
        This includes information such as session duration, bounce rate,
        approximate location, technical information such as browser type, screen
        resolution, or your Internet service provider. Other data may include
        contact information, any ratings, sharing content through social media,
        or adding to your favorites on YouTube.
      </p>
      <p>
        If you are not signed in to a Google Account or a YouTube account,
        Google stores data with a unique identifier associated with your device,
        browser or app. For example, your preferred language setting is
        preserved. But much interaction data cannot be stored because fewer
        cookies are set.
      </p>
      <p>
        In the following list we show cookies that were set in a test in the
        browser. On the one hand, we show cookies that are set without a
        registered YouTube account. On the other hand we show cookies that are
        set with a registered account. The list cannot claim to be complete,
        because the user data always depends on the interactions on YouTube.
      </p>
      <p>
        <strong>Name:</strong> YSC
        <br /> <strong>Value:</strong> b9-CV6ojI5Y311202202-1
        <br /> <strong>Purpose:</strong> This cookie registers a unique ID to
        store statistics of the video viewed.
        <br /> <strong>Expiration date:</strong> after the end of the session
      </p>
      <p>
        <strong>Name:</strong> PREF
        <br /> <strong>Value:</strong> f1=50000000
        <br /> <strong>Purpose:</strong> This cookie also registers your unique
        ID. Google gets statistics about how you use YouTube videos on our
        website via PREF.
        <br /> <strong>Expiration date:</strong> after 8 months
      </p>
      <p>
        <strong>Name:</strong> GPS
        <br /> <strong>Value:</strong> 1<br /> <strong>Purpose:</strong> This
        cookie registers your unique ID on mobile devices to track your GPS
        location.
        <br /> <strong>Expiration date:</strong> after 30 minutes
      </p>
      <p>
        <strong>Name:</strong> VISITOR_INFO1_LIVE
        <br /> <strong>Value:</strong> 95Chz8bagyU
        <br /> <strong>Purpose:</strong> This cookie tries to estimate the
        bandwidth of the user on our websites (with built-in YouTube video).
        <br /> <strong>Expiration date:</strong> after 8 months
      </p>
      <p>
        Other cookies that are set when you are logged in with your YouTube
        account:
      </p>
      <p>
        <strong>Name:</strong> APISID
        <br /> <strong>Wert:</strong> zILlvClZSkqGsSwI/AU1aZI6HY7311202202-
        <br /> <strong>Purpose:</strong> This cookie is used to create a profile
        about your interests. The data is used for personalized advertisements.
        <br /> <strong>Expiration date:</strong> after 2 years
      </p>
      <p>
        <strong>Name:</strong> CONSENT
        <br /> <strong>Value:</strong> YES+AT.de+20150628-20-0
        <br /> <strong>Purpose:</strong> The cookie stores the status of a
        user's consent to use various Google services. CONSENT is also used for
        security purposes to verify users and protect user data from
        unauthorized attacks.
        <br /> <strong>Expiration date: </strong>after 19 years
      </p>
      <p>
        <strong>Name:</strong> HSID
        <br /> <strong>Value:</strong> AcRwpgUik9Dveht0I
        <br /> <strong>Purpose:</strong> This cookie is used to create a profile
        about your interests. This data helps us to display personalized
        advertising.
        <br /> <strong>Expiration date:</strong> after 2 years
      </p>
      <p>
        <strong>Name:</strong> LOGIN_INFO
        <br /> <strong>Value:</strong> AFmmF2swRQIhALl6aL..
        <br /> <strong>Purpose:</strong> Information about your login data is
        stored in this cookie.
        <br /> <strong>Expiration date:</strong> after 2 years
      </p>
      <p>
        <strong>Name:</strong> SAPISID
        <br /> <strong>Value:</strong> 7oaPxoG-pZsJuuF5/AnUdDUIsJ9iJz2vdM
        <br /> <strong>Purpose:</strong> This cookie works by uniquely
        identifying your browser and device. It is used to create a profile
        about your interests.
        <br /> <strong>Expiration date:</strong> after 2 years
      </p>
      <p>
        <strong>Name:</strong> SID
        <br /> <strong>Value:</strong> oQfNKjAsI311202202-
        <br /> <strong>Purpose:</strong> This cookie stores your Google Account
        ID and your last login date in digitally signed and encrypted form.
        <br /> <strong>Expiration date:</strong> after 2 years
      </p>
      <p>
        <strong>Name:</strong> SIDCC
        <br /> <strong>Value:</strong> AN0-TYuqub2JOcDTyL
        <br /> <strong>Purpose:</strong> This cookie stores information about
        how you use the website and what advertisements you may have seen before
        visiting our site.
        <br /> <strong>Expiration date:</strong> after 3 months
      </p>
      <p>
        <strong>How long and where is the data stored?</strong>
      </p>
      <p>
        The data that YouTube receives from you and processes are stored on
        Google's servers. Most of these servers are located in America. At{" "}
        <a href="https://www.google.com/about/datacenters/inside/locations/?hl=de">
          https://www.google.com/about/datacenters/inside/locations/?hl=de
        </a>{" "}
        you can see exactly where the Google data centers are located. Your data
        is distributed on the servers. So the data can be retrieved faster and
        is better protected against manipulation.
      </p>
      <p>
        Google stores the collected data for different lengths of time. Some
        data can be deleted at any time, others are automatically deleted after
        a limited time and some are stored by Google for a longer time. Some
        data (such as items from "My activity", photos or documents, products)
        stored in your Google Account will remain stored until you delete them.
        Even if you are not signed in to a Google Account, you can delete some
        data associated with your device, browser, or app.
      </p>
      <p>
        <strong>How can I delete my data or prevent data storage?</strong>
      </p>
      <p>
        Basically you can manually delete data in your Google Account. With the
        automatic deletion function of location and activity data introduced in
        2019, information is stored for either 3 or 18 months, depending on your
        decision, and then deleted.
      </p>
      <p>
        Whether you have a Google Account or not, you can configure your browser
        to delete or disable Google cookies. This works in different ways
        depending on the browser you are using. The following instructions will
        show you how to manage cookies in your browser:
      </p>
      <p>
        <a href="https://support.google.com/chrome/answer/95647?tid=311202202">
          Chrome: Delete, activate and manage cookies in Chrome
          <span class="Apple-converted-space">&nbsp;</span>
        </a>
      </p>
      <p>
        <a href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=311202202">
          Safari: Manage cookies and website data with Safari
          <span class="Apple-converted-space">&nbsp;</span>
        </a>
      </p>
      <p>
        <a href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=311202202">
          Firefox: delete cookies to remove data that websites have placed on
          your computer<span class="Apple-converted-space">&nbsp;</span>
        </a>
      </p>
      <p>
        <a href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=311202202">
          Internet Explorer: Delete and manage cookies
          <span class="Apple-converted-space">&nbsp;</span>
        </a>
      </p>
      <p>
        <a href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=311202202">
          Microsoft Edge: Delete and manage cookies
          <span class="Apple-converted-space">&nbsp;</span>
        </a>
      </p>
      <p>
        If you do not wish to receive cookies, you can set your browser to
        notify you whenever a cookie is set. In this way, you can decide for
        each individual cookie whether you want to allow it or not. Since
        YouTube is a subsidiary of Google, there is a common privacy policy. If
        you would like to learn more about how we handle your information, we
        recommend that you read the privacy policy at{" "}
        <a href="https://policies.google.com/privacy?hl=de">
          https://policies.google.com/privacy?hl=de.
          <span class="Apple-converted-space">&nbsp;</span>
        </a>
      </p>
      <p>
        <strong>Cookiebot Privacy Policy</strong>
      </p>
      <p>
        We use functions of the provider Cookiebot on our website. The company
        behind Cookiebot is Cybot A/S, Havnegade 39, 1058 Copenhagen, DK. Among
        other things, Cookiebot offers us the possibility to provide you with a
        comprehensive cookie notice (also called cookie banner or cookie
        notice). By using this function, data from you can be sent to Cookiebot
        or Cybot, stored and processed. In this privacy policy we inform you why
        we use Cookiebot, which data is transferred and how you can prevent this
        data transfer.
      </p>
      <p>
        <strong>What is Cookiebot?</strong>
      </p>
      <p>
        Cookiebot is a software product of the company Cybot. The software
        automatically creates a DSGVO compliant cookie hint for our website
        visitors. In addition, the technology behind Cookiebot scans, controls
        and evaluates all cookies and tracking measures on our website.
      </p>
      <p>
        <strong>Why do we use Cookiebot on our website?</strong>
      </p>
      <p>
        We take data protection very seriously. We want to show you exactly what
        is going on on our website and which of your data is stored. Cookiebot
        helps us to get a good overview of all our cookies (first and third
        party cookies). So we can inform you about the use of cookies on our
        website in an exact and transparent way. You will always receive an
        up-to-date and data protection compliant cookie notice and decide
        yourself which cookies you allow and which you do not.
      </p>
      <p>
        <strong>Which data is stored by Cookiebot?</strong>
      </p>
      <p>
        If you allow cookies, the following data will be transmitted to Cybot,
        stored and processed.
      </p>
      <ul>
        <li>IP address (in anonymous form, the last 3 digits are set to 0)</li>
        <li>Date and time of your consent</li>
        <li>our website URL</li>
        <li>technical browser data</li>
        <li>encrypted, anonymous key</li>
        <li>the cookies you have allowed (as proof of consent)</li>
      </ul>
      <p>
        The following cookies are set by Cookiebot if you have agreed to the use
        of cookies:
      </p>
      <p>
        <strong>Name:</strong> CookieConsent
        <br /> <strong>Wert:</strong>{" "}
        stamp:'P7to4eNgIHvJvDerjKneBsmJQd9311202202-2
        <br /> <strong>Purpose:</strong> In this cookie your consent status is
        stored. This enables our website to read and follow the current status
        during future visits.
        <br /> <strong>Expiration date:</strong> after one year
      </p>
      <p>
        <strong>Name:</strong> CookieConsentBulkTicket
        <br /> <strong>Value:</strong>{" "}
        kDSPWpA%2fjhljhljZKClPqsncfR8SveTnNWhys5NojaxdFYBPjZ2PaDnUw%3d%3311202202-6
        <br /> <strong>Purpose:</strong> This cookie will be set if you allow
        all cookies and thus have activated a "collective consent". The cookie
        then stores its own random and unique ID.
        <br /> <strong>Expiration date: </strong>after one year
      </p>
      <p>
        <strong>Note: </strong>Please keep in mind that this is an exemplary
        list and that we cannot claim to be complete. In the cookie declaration
        at{" "}
        <a href="https://www.cookiebot.com/de/cookie-declaration/">
          https://www.cookiebot.com/de/cookie-declaration/
        </a>{" "}
        you can see which other cookies can be used.
      </p>
      <p>
        According to Cybot's privacy policy, the company does not sell personal
        information. However, Cybot does share data with trusted third parties
        or subcontractors that help the company to achieve its business
        objectives. Data will also be passed on when legally required.
      </p>
      <p>
        <strong>How long and where is the data stored?</strong>
      </p>
      <p>
        All collected data will be transferred, stored and forwarded exclusively
        within the European Union. The data is stored in an Azure data center
        (cloud provider is Microsoft). You can find out more about all "Azure
        regions" at
        https://azure.microsoft.com/de-de/global-infrastructure/regions/. All
        user data is deleted by Cookiebot after 12 months from registration
        (cookie consent) or immediately after cancellation of the Cookiebot
        service.
      </p>
      <p>
        <strong>How can I delete my data or prevent data storage?</strong>
      </p>
      <p>
        You have the right to access and delete your personal data at any time.
        You can prevent data collection and storage, for example, by rejecting
        the use of cookies via the cookie notice. Your browser offers another
        possibility to prevent data processing or to manage it according to your
        wishes. Depending on your browser, cookie management works slightly
        differently. Here you will find the instructions for the currently most
        popular browsers:
      </p>
      <p>
        <a href="https://support.google.com/chrome/answer/95647?tid=311202202">
          Chrome: Delete, activate and manage cookies in Chrome
          <span class="Apple-converted-space">&nbsp;</span>
        </a>
      </p>
      <p>
        <a href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=311202202">
          Safari: Manage cookies and website data with Safari
          <span class="Apple-converted-space">&nbsp;</span>
        </a>
      </p>
      <p>
        <a href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=311202202">
          Firefox: delete cookies to remove data that websites have placed on
          your computer<span class="Apple-converted-space">&nbsp;</span>
        </a>
      </p>
      <p>
        <a href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=311202202">
          Internet Explorer: Delete and manage cookies
          <span class="Apple-converted-space">&nbsp;</span>
        </a>
      </p>
      <p>
        <a href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=311202202">
          Microsoft Edge: Delete and manage cookies
          <span class="Apple-converted-space">&nbsp;</span>
        </a>
      </p>
      <p>
        If you want to learn more about the privacy policy of "Cookiebot" or the
        company behind it, Cybot, we recommend that you read the privacy policy
        at{" "}
        <a href="https://www.cookiebot.com/de/privacy-policy/?tid=311202202">
          https://www.cookiebot.com/de/privacy-policy/.
        </a>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>jQuery CDN Privacy Policy</strong>
      </p>
      <p>
        In order to deliver our website or all our individual sub-pages (web
        pages) to you quickly and easily on different devices, we use services
        from jQuery CDN from the jQuery Foundation. jQuery is distributed via
        the Content Delivery Network (CDN) of the American software company
        StackPath (LCC 2012 McKinney Ave. Suite 1100, Dallas, TX 75201, USA).
        Through this service, personal information about you is stored, managed
        and processed.
      </p>
      <p>
        A Content Delivery Network (CDN) is a network of regionally distributed
        servers that are connected to each other via the Internet. Through this
        network, content, especially very large files, can be delivered quickly,
        even during high load peaks.
      </p>
      <p>
        jQuery uses JavaScript libraries to deliver our website content quickly.
        A CDN server loads the necessary files. As soon as a connection to the
        CDN server is established, your IP address is recorded and saved. This
        only happens if this data has not already been saved in your browser by
        a previous website visit.
      </p>
      <p>
        StackPath's privacy policy explicitly mentions that StackPath uses
        aggregated and anonymized data from various services (such as jQuery) to
        enhance security and for its own services. However, this data cannot
        identify you as a person.
      </p>
      <p>
        If you do not want this data transfer to occur, you always have the
        option of installing Java Script blockers such as{" "}
        <a href="https://www.ghostery.com/de/">ghostery.com</a> or{" "}
        <a href="https://noscript.net/">noscript.net.</a> You can also simply
        deactivate the execution of JavaScript codes in your browser. If you
        decide to deactivate JavaScript codes, the usual functions will also
        change. For example, a website will no longer load as quickly.
      </p>
      <p>
        StackPath is an active participant in the EU-U.S. Privacy Shield
        Framework, which regulates the correct and secure transfer of personal
        data. More information can be found at{" "}
        <a href="https://www.privacyshield.gov/participant?id=a2zt0000000CbahAAC&amp;status=Active">
          https://www.privacyshield.gov/participant?id=a2zt0000000CbahAAC&amp;status=Active.
          For more information about
        </a>{" "}
        StackPath's
        <br /> <span class="Apple-converted-space">&nbsp;</span>privacy policy,
        please visit{" "}
        <a href="https://www.stackpath.com/legal/privacy-statement/?tid=311202202">
          https://www.stackpath.com/legal/privacy-statement/
        </a>{" "}
        and jQuery at
        https://openjsf.org/wp-content/uploads/sites/84/2019/11/OpenJS-Foundation-Privacy-Policy-2019-11-15.pdf.
      </p>
      <p>
        <span class="Apple-converted-space">&nbsp;</span>
      </p>
    </>
  )
}
